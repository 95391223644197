import timg from '../images/resource/Sheikha-Mahra-Al-Maktoum.webp'
import timg0 from '../images/resource/yaqoob-ali.webp'
import timg1 from '../images/resource/eng.-ahmed-al-hosani.webp'
import timg2 from '../images/resource/jimmy-mistry.webp'
import timg3 from '../images/resource/Ashish-Tripathi.webp'
import timg4 from '../images/resource/Dr.-Romit-Purohit.webp'
// import timg5 from '../images/resource/Dr.-Romit-Purohit.webp'
// import timg6 from '../images/resource/speaker-1.jpg'
// import timg7 from '../images/resource/speaker-1.jpg'

import tsimg from '../images/resource/Sheikha-Mahra-Al-Maktoum.webp'
import tsimg0 from '../images/resource/yaqoob-ali.webp'
import tsimg1 from '../images/resource/eng.-ahmed-al-hosani.webp'
import tsimg2 from '../images/resource/jimmy-mistry.webp'
import tsimg3 from '../images/resource/Ashish-Tripathi.webp'
import tsimg4 from '../images/resource/Dr.-Romit-Purohit.webp'
// import tsimg5 from '../images/resource/Dr.-Romit-Purohit.webp'
// import tsimg6 from '../images/resource/speaker-1.jpg'
// import tsimg7 from '../images/resource/speaker-1.jpg'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Sheikha Mahra Al Maktoum',
      slug: "javascript:void(0)",
      title: "Daughter of His Highness Sheikh Mohammed Bin Rashid Al Maktoum, The Vice President & Prime Minister of the UAE and Ruler of Dubai.",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Yaqoob Al Ali',
      slug: "javascript:void(0)",
      title: "Executive Director & Private Advisor, His Highness Sheikh Juma Bin Maktoum Al Maktoum, UAE",
   },
   {
      Id: '3',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Eng. Ahmed Al Hosani',
      slug: "https://www.linkedin.com/in/singer-ahmed-alhosani-8a329a231/",
      title: "UAE's First Classical Singer, Emirati Musicians' Association",
   },
   {
      Id: '4',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Jimmy Mistry',
      slug: 'https://www.linkedin.com/in/jimmy-mistry/',
      title: 'CMD, Della Group, Singapore',
   },
   {
      Id: '5',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Ashish Tripathi',
      slug: "javascript:void(0)",
      title: 'Chief Executive Officer, Tzar Labs',
   },
   {
      Id: '6',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Romit Purohit',
      slug: "https://www.linkedin.com/in/romit-purohit-279b7123b/",
      title: 'Head of Health, Dewa, UAE',
   },
   

]

export default Teams;