import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/news/2024-29.png'
import pimg2 from '../../images/news/2024-30.png'
import pimg3 from '../../images/news/2024-31.png'
import pimg4 from '../../images/news/2024-33.png'
import pimg5 from '../../images/news/2024-34.png'
import pimg6 from '../../images/news/2024-35.png'
import pimg7 from '../../images/news/2024-36.png'
import pimg8 from '../../images/news/2024-37.png'
import pimg9 from '../../images/news/2024-38.png'
import pimg10 from '../../images/news/2024-39.png'
import pimg11 from '../../images/news/2024-40.png'
import pimg12 from '../../images/news/2024-41.png'
import pimg13 from '../../images/news/2024-42.png'
import pimg14 from '../../images/news/2024-43.png'
import pimg15 from '../../images/news/2024-44.png'
import pimg16 from '../../images/news/2024-45.png'
import pimg17 from '../../images/news/2024-46.png'
import pimg18 from '../../images/news/2024-47.png'
import pimg19 from '../../images/news/2024-48.png'
import pimg20 from '../../images/news/2024-49.png'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
    {
        pImg: pimg9,
    },
    {
        pImg: pimg10,
    },
    {
        pImg: pimg11,
    },
    {
        pImg: pimg12,
    },
    {
        pImg: pimg13,
    },
    {
        pImg: pimg14,
    },
    {
        pImg: pimg15,
    },
    {
        pImg: pimg16,
    },
    {
        pImg: pimg17,
    },
    {
        pImg: pimg18,
    },
    {
        pImg: pimg19,
    },
    {
        pImg: pimg20,
    },
]


const PartnerSection = (props) => {

    return (

        <section className="partner-one"  style={{marginTop: '30px'}}>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18', fontSize: '30px', marginBottom: '-20px' }}>On Ground Media Coverage</h2>
                </div>
                    {/* <h2 className="sec-title_heading">Partners</h2> */}
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="row">
                            {partners.map((partner, pitem) => (
                                <div className=" col-lg-2 col-md-2 col-sm-2" style={{padding: '0px'}} key={pitem}>
                                    <div  style={{ padding: '13px'}}>
                                        <img src={partner.pImg} alt="" style={{ width: '170px'}} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;