import eimg1 from '../images/resource/facilitated-networking.png'
import eimg2 from '../images/resource/opening-act.png'
import eimg3 from '../images/resource/opening-address.png'
import eimg4 from '../images/resource/purple-carpet.png'
import eimg5 from '../images/resource/thought-provoking-keynotes.png'
import eimg6 from '../images/resource/awards-night.png'


const Events = [
    {
        Id: '1',
        eTitle: 'Facilitated Networking', 
        slug:'Facilitated Networking',
        eImg:eimg1,
        day:'First Day',
        date:'JANUARY 5, 2022',
        time:'03:00 PM to 05:00 PM',
        // speaker:'Paul Wilson',
        Icon:'flaticon-voice',
        des:'Empower connections and foster relationships through facilitated networking.',
    },
    {
        Id: '2',
        eTitle: 'Purple Carpet', 
        slug:'Purple Carpet',
        eImg:eimg2,
        day:'Second Day',
        date:'JANUARY 6, 2022',
        time:'05:00 PM to 06:00 PM',
        // speaker:'Ashli Scroggy',
        Icon:'flaticon-voice',
        Sclass:'style-two',
        des:'Walk the Purple Carpet and be part of an unforgettable evening filled with elegance and allure.',
    },
    {
        Id: '3',
        eTitle: 'Opening Address', 
        slug:'Opening Address',
        eImg:eimg3,
        day:'Thired Day',
        date:'JANUARY 7, 2022',
        time:'06:00 PM to 06:15 PM',
        // speaker:'Robert Gates',
        Icon:'flaticon-voice',
        des:'Participate in the Opening Address featuring our Chairman & Managing Director and our  Founder & CEO, as they lead us on an inspiring journey of excellence and achievement.',
    },
    {
        Id: '4',
        eTitle: 'Opening Act', 
        slug:'Opening Act',
        eImg:eimg4,
        day:'Forth Day',
        date:'JANUARY 8, 2022',
        time:'06:15 PM to 06:30 PM',
        // speaker:'Paul Wilson',
        Sclass:'style-two',
        Icon:'flaticon-voice',
        des:'Join us for the grand Opening Ceremony, where we embark on a journey of excellence and achievement.',
    },
    {
        Id: '5',
        eTitle: 'Thought Provoking Keynotes', 
        slug:'Thought Provoking Keynotes',
        eImg:eimg5,
        day:'Forth Day',
        date:'JANUARY 8, 2022',
        time:'06:30 PM to 07:15 PM',
        // speaker:'Paul Wilson',
        Sclass:'style-two',
        Icon:'flaticon-voice',
        des:'Unlocking the Potential Within: Keynotes that Inspire Action, Ignite Change, and Challenge Perspectives.',
    },
    {
        Id: '6',
        eTitle: 'Awards Night', 
        slug:'Awards Night',
        eImg:eimg6,
        day:'Forth Day',
        date:'JANUARY 8, 2022',
        time:'07:15 PM to 10:30 PM',
        // speaker:'Paul Wilson',
        Sclass:'style-two',
        Icon:'flaticon-voice',
        des:'Join us for an unforgettable Awards Night followed by dinner, where we raise a toast to success and revel in the joy of shared achievements.',
    },
]

export default Events;