import React from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

// Import images
import pImg1 from '../../images/gallery/1.webp'
import pImg2 from '../../images/gallery/2.webp'
import pImg3 from '../../images/gallery/3.webp'
import pImg4 from '../../images/gallery/4.webp'
import pImg5 from '../../images/gallery/5.webp'
import pImg6 from '../../images/gallery/6.webp'
import pImg7 from '../../images/gallery/7.webp'
import pImg8 from '../../images/gallery/8.webp'
import pImg9 from '../../images/gallery/9.webp'
import pImg10 from '../../images/gallery/10.webp'
import pImg11 from '../../images/gallery/11.webp'
import pImg12 from '../../images/gallery/12.webp'
import pImg13 from '../../images/gallery/13.webp'
import pImg14 from '../../images/gallery/14.webp'
import pImg15 from '../../images/gallery/15.webp'
import pImg16 from '../../images/gallery/16.webp'
import pImg17 from '../../images/gallery/17.webp'
import pImg18 from '../../images/gallery/18.webp'
import pImg19 from '../../images/gallery/19.webp'
import pImg20 from '../../images/gallery/20.webp'
import pImg21 from '../../images/gallery/21.webp'
import pImg22 from '../../images/gallery/22.webp'
import pImg23 from '../../images/gallery/23.webp'
import pImg24 from '../../images/gallery/24.webp'
import pImg25 from '../../images/gallery/25.webp'
import pImg26 from '../../images/gallery/26.webp'
import pImg27 from '../../images/gallery/27.webp'
import pImg28 from '../../images/gallery/28.webp'
import pImg29 from '../../images/gallery/29.webp'
import pImg30 from '../../images/gallery/30.webp'
import pImg31 from '../../images/gallery/31.webp'
import pImg32 from '../../images/gallery/32.webp'
import pImg33 from '../../images/gallery/33.webp'
import pImg34 from '../../images/gallery/34.webp'
import pImg35 from '../../images/gallery/35.webp'
import pImg36 from '../../images/gallery/36.webp'
import pImg37 from '../../images/gallery/37.webp'
import pImg38 from '../../images/gallery/38.webp'
import pImg39 from '../../images/gallery/39.webp'
import pImg40 from '../../images/gallery/40.webp'
import pImg41 from '../../images/gallery/41.webp'
import pImg42 from '../../images/gallery/42.webp'
import pImg43 from '../../images/gallery/43.webp'
import pImg44 from '../../images/gallery/44.webp'
import pImg45 from '../../images/gallery/45.webp'
import pImg46 from '../../images/gallery/56.webp'
import pImg47 from '../../images/gallery/47.webp'
import pImg48 from '../../images/gallery/48.webp'
import pImg49 from '../../images/gallery/49.webp'
import pImg50 from '../../images/gallery/50.webp'
import pImg51 from '../../images/gallery/51.webp'
import pImg52 from '../../images/gallery/52.webp'
import pImg53 from '../../images/gallery/53.webp'
import pImg54 from '../../images/gallery/54.webp'
import pImg55 from '../../images/gallery/55.webp'
import pImg56 from '../../images/gallery/56.webp'
import pImg57 from '../../images/gallery/57.webp'
import pImg58 from '../../images/gallery/58.webp'
import pImg59 from '../../images/gallery/59.webp'
import pImg60 from '../../images/gallery/60.webp'
import pImg61 from '../../images/gallery/61.webp'
import pImg62 from '../../images/gallery/62.webp'
import pImg63 from '../../images/gallery/63.webp'
import pImg64 from '../../images/gallery/64.webp'
import pImg65 from '../../images/gallery/65.webp'
import pImg66 from '../../images/gallery/66.webp'
import pImg67 from '../../images/gallery/67.webp'
import pImg68 from '../../images/gallery/68.webp'
import pImg69 from '../../images/gallery/69.webp'
import pImg70 from '../../images/gallery/70.webp'
import pImg71 from '../../images/gallery/71.webp'
import pImg72 from '../../images/gallery/72.webp'
import pImg73 from '../../images/gallery/73.webp'
import pImg74 from '../../images/gallery/74.webp'
import pImg75 from '../../images/gallery/75.webp'
import pImg76 from '../../images/gallery/76.webp'
import pImg77 from '../../images/gallery/77.webp'
import pImg78 from '../../images/gallery/78.webp'
import pImg79 from '../../images/gallery/79.webp'
import pImg80 from '../../images/gallery/80.webp'
import pImg81 from '../../images/gallery/81.webp'
import pImg82 from '../../images/gallery/82.webp'
import pImg83 from '../../images/gallery/83.webp'
import pImg84 from '../../images/gallery/84.webp'
import pImg85 from '../../images/gallery/85.webp'
import pImg86 from '../../images/gallery/86.webp'
import pImg87 from '../../images/gallery/87.webp'
import pImg88 from '../../images/gallery/88.webp'
import pImg89 from '../../images/gallery/89.webp'
import pImg90 from '../../images/gallery/90.webp'
import pImg91 from '../../images/gallery/91.webp'
import pImg92 from '../../images/gallery/92.webp'
import pImg93 from '../../images/gallery/93.webp'
import pImg94 from '../../images/gallery/94.webp'
import pImg95 from '../../images/gallery/95.webp'
import pImg96 from '../../images/gallery/96.webp'
// import pImg97 from '../../images/gallery/97.webp'
import pImg98 from '../../images/gallery/98.webp'
import pImg99 from '../../images/gallery/99.webp'
import pImg100 from '../../images/gallery/100.webp'
// Add all your imports...

// Array of images
const images = [
    pImg1,
    pImg2,
    pImg3,
    pImg4,
    pImg5,
    pImg6,
    pImg7,
    pImg8,
    pImg9,
    pImg10,
    pImg11,
    pImg12,
    pImg13,
    pImg14,
    pImg15,
    pImg16,
    pImg17,
    pImg18,
    pImg19,
    // pImg20,
    // pImg21,
    pImg22,
    pImg23,
    pImg24,
    pImg25,
    pImg26,
    pImg27,
    pImg28,
    pImg29,
    pImg30,
    pImg31,
    pImg32,
    pImg33,
    pImg34,
    pImg35,
    pImg36,
    pImg37,
    pImg38,
    pImg39,
    pImg40,
    pImg41,
    pImg42,
    pImg43,
    pImg44,
    pImg45,
    pImg46,
    pImg47,
    pImg48,
    pImg49,
    pImg50,
    pImg51,
    pImg52,
    pImg53,
    pImg54,
    pImg55,
    pImg56,
    pImg57,
    pImg58,
    pImg59,
    pImg60,
    pImg61,
    pImg62,
    pImg63,
    pImg64,
    pImg65,
    pImg66,
    pImg67,
    pImg68,
    pImg69,
    pImg70,
    pImg71,
    pImg72,
    pImg73,
    pImg74,
    pImg75,
    pImg76,
    pImg77,
    pImg78,
    pImg79,
    // pImg80,
    // pImg81,
    pImg82,
    pImg83,
    // pImg84,
    pImg85,
    pImg86,
    pImg87,
    pImg88,
    pImg89,
    pImg90,
    pImg91,
    pImg92,
    pImg93,
    pImg94,
    pImg95,
    pImg96,
    // pImg97,
    pImg98,
    pImg99,
    pImg100,
    // pImg9,
    // pImg9,
    // pImg9,
    // pImg9,
]

// Convert images to slides
const slides = images.map((image) => ({ src: image }));

const GallerySection3 = () => {
    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);

    // Handle image click
    const handleImageClick = (index) => {
        setCurrentIndex(index); // Set the index of the clicked image
        setOpen(true); // Open the lightbox
    };

    return (
        <section className="gallery-three">
            <div className="auto-container">
                <div className="row clearfix">
                    {images.map((item, index) => (
                        <div
                            className="gallery-one_block col-lg-4 col-md-4 col-sm-6"
                            key={index}
                        >
                            <div className="gallery-one_block-inner">
                                <div className="gallery-one_block-image">
                                    <img src={item} alt={`Gallery Image ${index + 1}`} />
                                    {/* <div className="gallery-one_block-overlay">
                                        <span className="gallery-one_block-icon flaticon-plus-symbol"></span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides} // Use the slides array
                currentIndex={currentIndex} // Show the clicked image
                plugins={[Zoom]}
            />
        </section>
    );
};

export default GallerySection3;

