import timg1 from '../images/resource/jimmy-mistry.webp'
import timg2 from '../images/resource/manjulangani.webp'
import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/Amarjit-Singh.jpg'
import timg5 from '../images/resource/Arun-Kumar-Singh.jpg'
import timg6 from '../images/resource/amit-sheth.webp'
import timg7 from '../images/resource/Subramanyam-Yadavalli2.webp'
import timg8 from '../images/resource/speaker-1.jpg'
import timg9 from '../images/resource/pratit-samdani.webp'
import timg10 from '../images/resource/riitesh-mehtaa.webp'
import timg11 from '../images/resource/Anjlee.jpg'
import timg12 from '../images/resource/shailza-taneja.jpg'
import timg13 from '../images/resource/Manoj-Mohta.jpg'
import timg14 from '../images/resource/Ravi-Nayar.jpg'
import timg15 from '../images/resource/Mantosh-Roy.jpg'
import timg16 from '../images/resource/Varinder-Kumar-Verma.jpg'

import tsimg1 from '../images/resource/jimmy-mistry.webp'
import tsimg2 from '../images/resource/manjulangani.webp'
import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/Amarjit-Singh.jpg'
import tsimg5 from '../images/resource/Arun-Kumar-Singh.jpg'
import tsimg6 from '../images/resource/amit-sheth.webp'
import tsimg7 from '../images/resource/Subramanyam-Yadavalli2.webp'
import tsimg8 from '../images/resource/speaker-1.jpg'
import tsimg9 from '../images/resource/pratit-samdani.webp'
import tsimg10 from '../images/resource/riitesh-mehtaa.webp'
import tsimg11 from '../images/resource/Anjlee.jpg'
import tsimg12 from '../images/resource/shailza-taneja.jpg'
import tsimg13 from '../images/resource/Manoj-Mohta.jpg'
import tsimg14 from '../images/resource/Ravi-Nayar.jpg'
import tsimg15 from '../images/resource/Mantosh-Roy.jpg'
import tsimg16 from '../images/resource/Varinder-Kumar-Verma.jpg'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'https://www.linkedin.com/in/bimal-chhajer-24a4aa29/',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'https://www.linkedin.com/in/dr-manjula-anagani-a5283718/   ',
      title: 'Clinical Director, Women and Child Institute, Care Hospital, India',
   },
   {
      Id: '3',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Amit Sheth',
      slug: "javascript:void(0)",
      title: 'Award-winning Serial Entrepreneur & Philanthropist, India',
   },
   {
      Id: '7',
      tImg: timg7,
      tsImg: tsimg7,
      name: 'Subramanyam Yadavalli',
      slug: 'https://www.linkedin.com/in/subramanyam-yadavalli-6ba30059/',
      title: 'CEO, Apollo Hospitals, India',
   },
   {
      Id: '8',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr.(Brig) Amarjit Singh',
      slug: 'https://www.linkedin.com/in/dr-brig-amarjit-singh-50b1458/',
      title: 'CEO & Principal Director, Dr. D. Y. Patil Medical College Hospital & Research Centre, India',
   },
   {
      Id: '9',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Arun Kumar Singh',
      slug: 'https://www.linkedin.com/in/dr-arun-kumar-singh-54147547/',
      title: 'Development Commissioner & Additional Chief Secretary of Health, Government of Jharkhand, India',
   },
   {
      Id: '10',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Dr. Anjlee Prakash',
      slug: 'https://www.linkedin.com/in/anjleeprakash/',
      title: 'Founder & Chairperson, Learning Links Foundation, India',
   },
   {
      Id: '11',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Dr. Shailza Taneja',
      slug: 'https://www.linkedin.com/in/shailzataneja/',
      title: 'Founder & Director, CreativExpression, India',
   },
   {
      Id: '12',
      tImg: timg13,
      tsImg: tsimg13,
      name: 'Manoj Mohta',
      slug: 'javascript:void(0)',
      title: 'Whole-Time Director, CEO & CFO, JSW Holdings Ltd, India',
   },
   {
      Id: '12',
      tImg: timg14,
      tsImg: tsimg14,
      name: 'Ravi Nayar',
      slug: 'https://www.linkedin.com/in/ravi-nayar-2829648/?originalSubdomain=in',
      title: 'Entrepreneur, Hospitality Veteran, Managing Director at Mardi Gras Entertainment & Off Beat Adventure, India',
   },
   {
      Id: '12',
      tImg: timg15,
      tsImg: tsimg15,
      name: 'Mantosh Roy',
      slug: 'https://www.linkedin.com/in/mantyroy/',
      title: 'Serial Entrepreneur, Founder, Start-up Advisor, Angel Investor, Ex Nykaa, Co-Founder, Sammmm Beauty, India',
   },
   {
      Id: '12',
      tImg: timg16,
      tsImg: tsimg16,
      name: 'Varinder Kumar Verma',
      slug: 'https://www.linkedin.com/in/varindervermaom21000/',
      title: 'Head - HR Strategy & Transformation (Corporate Human Resources), Maruti Suzuki India Limited, India',
   },
]

export default Teams;