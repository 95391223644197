import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/Business-1.webp'
import abimg2 from '../../images/resource/Business-2.webp'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one business-on" id='about'>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                <h2 className="sec-title_heading">Introduction</h2>
                            </div>
                            <div className="business-one_text">
                                <p style={{textAlign: 'justify'}}>Forttuna Global Excellence Awards Program, established in July 2023 in the thriving city of Dubai, takes pride in its mission to honor and celebrate <a href="https://forttuna.co/award-categories/">exemplary business leaders</a> on a global scale. This prestigious global awards program is dedicated to <a href="https://participate.forttuna.co/">recognizing individuals</a> who have demonstrated outstanding leadership, innovation, and impactful contributions to their respective industries.</p>
                                <p style={{textAlign: 'justify'}}>Winning a Forttuna <Link to='/awards/business-awards/'>Business Award</Link> increases brand recognition and networking opportunities reaching a vast audience of potential customers, partners, and investors. Vast Award Categories like <Link to='/awards/small-business-awards/'>Small Business Awards </Link>and International Business Awards attracts top talent who want to contribute to a winning team.The <Link to='/corporate-recognition-awards/'>corporate recognition</Link> and prestige associated with a Forttuna Business Award can translate directly into increased sales and business growth.</p>
                            </div>

                            <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/schedule-business-leaders-global/" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Agenda</span>
                                        <span className="text-two">Agenda</span>
                                    </span>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;