import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: US Fintech Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Discover the US Fintech Awards, where your fintech innovation can shine on a global stage. Join us to celebrate excellence in financial technology." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards: US Fintech Awards" />
            <meta property="og:description" content="Discover the US Fintech Awards, where your fintech innovation can shine on a global stage. Join us to celebrate excellence in financial technology." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="US Fintech Awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: US Fintech Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Celebrate US Fintech Awards " />
                        </div>
                        <h2 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: US Fintech Awards</strong></h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led US Fintech Awards: Be Recognized on the World Stage</strong></h1>
                        <p>Are the US Fintech Awards for you? Yes, if you have a groundbreaking fintech product or service that is reshaping the financial industry.</p>
                        <p>
                            The Forttuna Global Excellence Awards provide an exceptional opportunity to showcase innovation! The prestigious fintech awards category celebrates remarkable achievements in financial technology, recognizing excellence in
                            <a href="https://www.provenir.com/finance-forward-10-breakthrough-innovations-reshaping-the-future-of-financial-services/">innovation</a>, design, and impactful contributions. It is for the ones defining the future of finance.
                        </p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna</strong> Global Excellence <strong>US Fintech Awards?</strong></h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Renowned Accolade:</strong> The Forttuna Global Excellence Awards recognize outstanding accomplishments by leaders in the US Fintech industry.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Distinguished Panel:</strong> An expert jury of industry leaders assesses submissions to guarantee a merit-based selection.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Diverse Sector Impact:</strong> Forttuna US Fintech Awards honor innovation across the fintech landscape.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Community of Innovators:</strong> <a href="https://www.forttuna.co/business-leaders-edition-india/">Forttuna</a> encourages connections and collaboration among similar-minded businesses.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>International Scope:</strong> Achieve recognition for your influence on the global stage.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Should You Participate in <strong>US Fintech Awards</strong>?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Earn global recognition for your company's innovative ideas and leadership in the fintech industry.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Enhance your brand's profile and attract new opportunities by being acknowledged on the international stage.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Connect with top minds in fintech industry.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Secure extensive media attention and amplify your achievements.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Make the most from a rewarding and transparent evaluation jury. Assessments are metric based and fair.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>US Fintech Awards Categories</h3>
                        <p><strong>Finance and Insurance</strong> category has a dedicated Fintech Award**-**</p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>FinTech Leader of the Year</li>
                        </ol>
                        <p>Other categories in Finance and Insurance are-</p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cash Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Compliance Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Finance Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Finance Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Banking Professional of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Impact Investor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Investment Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Regional Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wealth Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>Technology-</strong> Awards in tech category include-</p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Ad-Tech Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>AVP of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Blockchain Consultant of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Transformation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Interoperability Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Revenue Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cloud Computing Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Developer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Business of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Center Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Disruptor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Technology Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Transformation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Technology Advocate of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Icon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>IT Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Agriculture and Development</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Achievement Award in Engineering and Manufacturing</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Tech Innovator Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Power and Water Utilities Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sales and Marketing Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Cloud Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Utility Industry Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Website Design &amp; Development Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Technology Leader of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to step into the spotlight at the <strong>US Fintech Awards</strong>?</h4>
                        <p>Celebrate your fintech success by being a part of US Fintech Awards.</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now!</a></p>
                        <p>Know About <a href="https://forttuna.co/what-is-an-industry-award/">Industry Award</a>.</p>
                        <p>Learn More About <a href="https://forttuna.co/top-awards-for-businesses/">Top Awards For Businesses.</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
