import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence awards for entrepreneurs in India';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Join us in awards for entrepreneurs in India to elevate your entrepreneurial legacy. Inspire future entrepreneurs, network with industry leaders, and gain recognition. " />
            <meta property="og:title" content="The Forttuna Global Excellence awards for entrepreneurs in India" />
            <meta property="og:description" content="Join us in awards for entrepreneurs in India to elevate your entrepreneurial legacy. Inspire future entrepreneurs, network with industry leaders, and gain recognition. " />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="Forttuna awards for entrepreneurs in India" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence awards for entrepreneurs in India' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna awards for doctors in India" />
                        </div>
                        <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards For Entrepreneurs In India</h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Awards for E</strong>ntrepreneurs in India: Be Recognized on the World Stage</h1>
                        <p>Entrepreneur&rsquo;s do not just build a businesses, they craft legacies. Awards for Entrepreneurs in India, celebrate such pioneers of Indian entrepreneurship. The architects of vision, strategies, who turn ideas into reality.</p>
                        <p>The Forttuna Global Excellence Awards aim to spotlight these talented individuals. It is a revered stage for those entrepreneurs, challenging the status quo, creating jobs, and driving economic growth.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna</strong> Global Excellence awards for entrepreneurs in India?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Entrepreneurial Excellence Awards:</strong> An <a href="https://forttuna.co/">esteemed</a>, jury-driven, and merit-based program recognizing outstanding entrepreneurs who are reshaping industries and inspiring innovation.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Visionary Leader Honors:</strong> It celebrates individuals who are transforming the business landscape. <a href="https://hbr.org/2001/12/what-leaders-really-do">Leaders</a> who are making a significant impact on the global market.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Entrepreneur Network:</strong> Beyond the international reach, it also offers a unique opportunity to interact with professionals from various sectors.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in</strong> awards for entrepreneurs in India?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Achieve worldwide acclaim for your entrepreneurial legacy.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Inspire future generations of entrepreneurs with your achievements.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Connect with top industry leaders at awards for entrepreneurs in India ceremony .</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Enhance your brand's reputation and open doors to new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain significant media exposure and be in the limelight .</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Enjoy a fair assessment from a respected international jury.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Category of Awards for Entrepreneurs in India</h3>
                        <h4 style={{color: '#fff'}}>Business Leaders Category in Awards for Entrepreneurs in India</h4>
                        <p><strong>A. Architecture, Construction &amp; Engineering</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>B. Automobile</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>C. Education</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur in Higher Education of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>D. Entertainment and Media</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>E. Fashion and Beauty</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>F. Finance and Insurance</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>G. Hospitality</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>H. Human Resources</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>HR Leader &amp; Woman Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>I. Manufacturing</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>J. Marketing</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Female Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>K. Real Estate</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>L. SME&rsquo;s</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Women Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>M. Technology</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}><strong>Ready to shine? Participate Now!</strong></h4>
                        <p>Let's come together to celebrate your achievements at this prestigious awards for entrepreneurs in India.</p>
                        <p>Be a part of awards for entrepreneurs in India! <a href="https://participate.forttuna.co/">Participate Now!</a></p>
                        <p>Know Why <a href="https://forttuna.co/2024-award-shows/">Award Shows Matter in 2024</a>.</p>
                        <p>Learn More About <a href="https://forttuna.co/top-business-awards-in-india/">Top Business Awards in India.</a></p>
                        <h5 style={{color: '#fff'}}><strong>Forttuna Global Excellence Awards</strong></h5>
                        <p>Prepare for the upcoming <a href="https://www.forttuna.co/">Forttuna Global Excellence Awards</a>&nbsp;that are going to be held on <strong>6th and 7th, of December 2024 in Dubai</strong> and <strong>June 2025, in Mumbai.</strong> Come and celebrate all the brilliant business leaders and companies. Witness remarkable leaders from diverse industries and their stellar achievements.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
