import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/news/op1.webp'
import pimg2 from '../../images/news/op2.webp'
import pimg3 from '../../images/news/op3.webp'
import pimg4 from '../../images/news/op4.webp'
import pimg5 from '../../images/news/op5.webp'
import pimg6 from '../../images/news/op6.webp'
import pimg7 from '../../images/news/op7.webp'
import pimg8 from '../../images/news/op8.webp'
import pimg9 from '../../images/news/op9.webp'
import pimg10 from '../../images/news/op10.webp'
import pimg11 from '../../images/news/op11.webp'
import pimg12 from '../../images/news/op12.webp'
// import pimg7 from '../../images/clients/7.png'
// import pimg8 from '../../images/clients/8.png'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
    {
        pImg: pimg9,
    },
    {
        pImg: pimg10,
    },
    {
        pImg: pimg11,
    },
    {
        pImg: pimg12,
    },
]


const PartnerSection = (props) => {

    return (

        // <section className="clients-one">
        //     <div className="auto-container">
        //         <div className="sec-title title-anim centered">
        //         <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18' }}>Sponsors</h2>
        //         </div>
        //             {/* <h2 className="sec-title_heading">Partners</h2> */}
        //         <div className="row clearfix">
        //             <div className="col-lg-12">
        //                 <div className="row">
        //                     {partners.map((partner, pitem) => (
        //                         <div className="clients-one_column col-lg-2 col-md-3 col-sm-4" key={pitem}>
        //                             <Link to="/"><img src={partner.pImg} alt="" /></Link>
        //                         </div>
        //                     ))}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
        <section className="partner-one"  style={{marginTop: '40px'}}>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18', fontSize: '30px', marginBottom: '-20px', marginLeft: '15px' }}>Sponsors</h2>
                </div>
                    {/* <h2 className="sec-title_heading">Partners</h2> */}
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="row">
                            {partners.map((partner, pitem) => (
                                <div className="clients-one_column col-lg-2 col-md-2 col-sm-2" key={pitem}>
                                    <div  style={{ padding: '13px', }}>
                                        <Link to="/"><img src={partner.pImg} alt="" style={{width:'170px'}} /></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;