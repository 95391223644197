import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/Business-1.webp'
import abimg2 from '../../images/resource/Business-2.webp'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one business-on" id='about'>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                <h2 className="sec-title_heading">Introduction</h2>
                            </div>
                            <div className="business-one_text">
                                <p style={{textAlign: 'justify'}}>The Forttuna Global Excellence Awards Program, established in July 2023 in the dynamic city of Dubai, proudly dedicates itself to honoring and celebrating exemplary Green Leaders driving sustainability efforts on a global scale. This distinguished awards program recognizes individuals and organizations that have demonstrated exceptional leadership, innovation, and impactful contributions in advancing environmental sustainability, climate action, and green innovation across various industries.</p>
                                <p style={{textAlign: 'justify'}}>Winning a Forttuna Green Leaders Award not only amplifies recognition for impactful green initiatives but also fosters unparalleled networking opportunities with like-minded individuals, partners, and investors. This global platform highlights vast categories, including Environmental Leadership Awards and Sustainable Innovation Awards, attracting visionary leaders committed to making a difference. The prestige and corporate recognition associated with a Forttuna Green Leaders Award can inspire greater collaborations, enhance reputation, and pave the way for sustainable growth and influence in an increasingly eco-conscious world.</p>
                            </div>

                            <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/schedule-green-leaders/" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Agenda</span>
                                        <span className="text-two">Agenda</span>
                                    </span>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;