import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: B2B Advertising Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Showcase your company's excellence on the world stage. Enter the Business Excellence Awards and connect with a global network of industry pioneers." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards: B2B Advertising Awards" />
            <meta property="og:description" content="Showcase your company's excellence on the world stage. Enter the Business Excellence Awards and connect with a global network of industry pioneers." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="B2B Advertising Awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: B2B Advertising Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="B2B Advertising Awards - Be Recognized" />
                        </div>
                        <h2 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: B2B Advertising Awards</strong></h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury-Led B2B Advertising Awards: Be Recognized on the World Stage</strong></h1>
                        <p>
                            The B2B Advertising Awards believe in honoring the visionaries who are transforming business-to-business advertising. The awards recognizes the campaigns that captivate audiences, creative ideas that resonates with other businesses and
                            also <a href="https://springwise.com/advertising-and-marketing-innovations/">advertising innovations</a> that drive growth and establish stronger connections
                        </p>
                        <p>
                            The B2B Advertising Awards at the Forttuna Global Excellence Awards offer a large platform to showcase the creativity of your team. It also provides platforms to connect with industry influencers. It is the best chance to elevate your
                            brand's standing in the competitive B2B landscape. Join us to celebrate the advertising achievements that have set new standards and inspired excellence in the B2B arena.
                        </p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence B2B Advertising Awards?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Top Honors:</strong> Celebrates outstanding success in different fields.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Reach:</strong> Recognizes people and companies making a big impact worldwide.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Building Connections:</strong> Brings together like-minded people to work and grow together.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspiring New Leaders:</strong> Encourages and guides the next generation of <a href="https://www.forttuna.co/business-leaders-edition">business leaders</a>.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Promoting New Ideas:</strong> Supports creativity and fresh thinking in the business world.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate in the <strong>B2B Advertising Awards</strong>?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain national recognition for innovation and leadership:</strong> Showcase your company as an industry leader, gaining visibility and acknowledgment on a national scale.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspire the business community with outstanding achievements:</strong> Highlight your team&rsquo;s talent and accomplishments, setting a high standard for peers and inspiring others.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Network with industry leaders at India&rsquo;s top B2B advertising awards:</strong> Connect with influential business figures, building relationships that can foster new opportunities and growth.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Strengthen your brand profile and attract opportunities:</strong> Enhance credibility and attract potential clients, partners, and investors by positioning your brand among the best.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Gain valuable media coverage:</strong> Benefit from extensive exposure across media platforms, bringing attention to your achievements and amplifying your brand&rsquo;s reach.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Experience a fair evaluation by expert judges:</strong> Be proud of recognition from an esteemed panel, ensuring a transparent, credible, and unbiased assessment of your accomplishments.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>B2B Advertising Awards Categories :</h3>
                        <p><strong>A. Market Research</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Expert of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Research Services Organisation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Researcher of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Supply Chain Professional of the Year</li>
                        </ol>
                        <p><strong>B. Marketing</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Advertising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategy Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Commercial Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Content Marketer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Analytics Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Female Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Humanitarian Philanthropy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Business Idea of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Integrated Marketing Communications Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Media &amp; Advertising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sales Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Media Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sustainability Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>VP of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to shine a light?</h4>
                        <h5 style={{color: '#fff'}}>Participate today!</h5>
                        <p><a href="https://participate.forttuna.co/">Click here!</a></p>
                        <p>Let's celebrate your achievements together at this B2B Advertising Awards!</p>
                        <p>Be a part of the global excellence award.</p>
                        <p>Know About <a href="https://forttuna.co/international-business-awards/">International Business Award</a>.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
