import timg from '../images/resource/moran-cerf.jpg'
import timg0 from '../images/resource/amit-sheth.webp'
import timg1 from '../images/resource/jimmy-mistry.webp'
import timg2 from '../images/resource/jay-manglani.webp'
import timg3 from '../images/resource/micheal-conner.webp'

import tsimg from '../images/resource/moran-cerf.jpg'
import tsimg0 from '../images/resource/amit-sheth.webp'
import tsimg1 from '../images/resource/jimmy-mistry.webp'
import tsimg2 from '../images/resource/jay-manglani.webp'
import tsimg3 from '../images/resource/micheal-conner.webp'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Dr. Moran Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      title: "Professor of Neuroscience & Business, Columbia University, USA",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Why Participate ?',
      slug: "javascript:void(0)",
      title: "Award-winning Serial Entrepreneur & Philanthropist, India",
   },
   // {
   //    Id: '3',
   //    tImg: timg1,
   //    tsImg: tsimg1,
   //    name: 'Jimmy Mistry',
   //    slug: 'Jimmy Mistry',
   //    title: "CMD, Della Group, Singapore",
   // },
   // {
   //    Id: '4',
   //    tImg: timg2,
   //    tsImg: tsimg2,
   //    name: 'Jay Magdani',
   //    slug: 'Jay Magdani',
   //    title: "Chief Product Officer - Collective Artists Network, India",
   // },
   {
      Id: '5',
      tImg: timg3,
      tsImg: tsimg,
      name: 'Dr. Michael Conner',
      slug: "https://www.linkedin.com/in/michael-conner-ed-d-82033a21/",
      title: "CEO/Founder @ Agile Evolutionary Group, USA",
   },
]

export default Teams;