import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/partner/md1.webp'
import pimg2 from '../../images/partner/md2.webp'
import pimg3 from '../../images/partner/md3.webp'
import pimg4 from '../../images/partner/md4.webp'
// import pimg5 from '../../images/partner/Zeetv.png'
// import pimg7 from '../../images/partner/7.png'
// import pimg8 from '../../images/partner/8.png'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    // {
    //     pImg: pimg5,
    // },
]


const PartnerSection = (props) => {

    return (

        <section className="partner-one" style={{marginBottom: '40px', marginTop: '40px'}}>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18',  fontSize: '30px', marginBottom: '-20px', marginLeft: '15px' }}>Media Coverage</h2>
                </div>
                    {/* <h2 className="sec-title_heading">Partners</h2> */}
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="row">
                            {partners.map((partner, pitem) => (
                                <div className="partner-one_column col-lg-2 col-md-2 col-sm-2" key={pitem}>
                                    <div  style={{ padding: '13px',}}>
                                        <img src={partner.pImg} alt="" style={{width:'170px'}} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;



