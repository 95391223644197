import timg from '../images/resource/manjulangani.webp'
import timg0 from '../images/resource/Subramanyam-Yadavalli2.webp'
import timg1 from '../images/resource/moran-cerf.jpg'
import timg2 from '../images/resource/Dr.-Sylvia-Paulig.webp'
import timg3 from '../images/resource/bimal-chajjar.webp'
import timg4 from '../images/resource/Amarjit-Singh.jpg'
import timg5 from '../images/resource/pratit-samdani.webp'

import tsimg from '../images/resource/manjulangani.webp'
import tsimg0 from '../images/resource/Subramanyam-Yadavalli2.webp'
import tsimg1 from '../images/resource/moran-cerf.jpg'
import tsimg2 from '../images/resource/Dr.-Sylvia-Paulig.webp'
import tsimg3 from '../images/resource/bimal-chajjar.webp'
import tsimg4 from '../images/resource/Amarjit-Singh.jpg'
import tsimg5 from '../images/resource/pratit-samdani.webp'
// import tsimg8 from '../images/resource/team-16.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'https://www.linkedin.com/in/dr-manjula-anagani-a5283718/',
      title: "Clinical Director, Women and Child Institute, Care Hospital, India",
   },
   {
      Id: '2',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Subramanyam Yadavalli',
      slug: 'https://www.linkedin.com/in/subramanyam-yadavalli-6ba30059/',
      title: 'CEO, Apollo Hospitals, India',
   },
   {
      Id: '3',
      tImg: timg3,
      tsImg: tsimg3,
      name: 'Dr. Bimal Chhajer',
      slug: 'https://www.linkedin.com/in/bimal-chhajer-24a4aa29/',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },
   {
      Id: '4',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr.(Brig) Amarjit Singh',
      slug: 'https://www.linkedin.com/in/dr-brig-amarjit-singh-50b1458/',
      title: 'CEO & Principal Director, Dr. D. Y. Patil Medical College Hospital & Research Centre, India',
   },
   // {
   //    Id: '6',
   //    tImg: timg5,
   //    tsImg: tsimg5,
   //    name: 'Dr. Pratit Samdani',
   //    slug: 'Dr. Pratit Samdani',
   //    title: "Oncologist, Physician & Intensivist, India",
   // },

]

export default Teams;