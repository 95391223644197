import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards : Social media awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Social media awards laud the creative visionaries, data-driven strategists, and storytellers who are revolutionizing the social media landscape. Join us!" />
            <meta property="og:title" content="The Forttuna Global Excellence Awards : Social media awards" />
            <meta property="og:description" content="Social media awards laud the creative visionaries, data-driven strategists, and storytellers who are revolutionizing the social media landscape. Join us!" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="social media awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards : Social media awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="best social media awards" />
                        </div>
                        <h2 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Social Media Awards</strong></h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Social Media Awards: Be Recognized on the Global Stage</strong></h1>
                        <p>The Social media awards is a medium to acknowledge digital pioneers who are shaping our online world. The masterminds behind strategies that capture attention, ignite imaginations, and forge connections often remain unseen.</p>
                        <p>The Forttuna Global Excellence Awards shine a spotlight on such brilliant minds. They laud the creative visionaries, <a href="https://blog.dataddo.com/data-driven-social-media-strategy">data-driven</a> strategists, and storytellers who are revolutionizing the social media landscape.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global Excellence Social Media</strong> <strong>Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Accolade:</strong> Commemorates social media experts by a revered jury-led and merit-based awards program.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Worldwide Respect</strong>: Recognizes individuals and organizations that have made a significant impact on the global stage through social media.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Social Platform:</strong> An opportunity to connect and collaborate with fellow digital innovators and other industry experts.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in Social Media</strong> <strong>Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Attain <a href="https://forttuna.co/">global prestige</a> for social media innovation.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emphasize your social media talent and motivate others through your notable achievements.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Engage in a collaborative space and meet social media leaders.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Exalt your brand story.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Experience substantial media coverage, bringing your successes into the limelight.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Grow under the impartial assessment by a renowned panel of international experts.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Social Media Awards Categories</strong></h3>
                        <p><strong>Marketing</strong> has a dedicated award for social media.</p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Social Media Innovator of the Year</li>
                        </ol>
                        <p>Other awards in Marketing category include-</p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Advertising Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategy Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Coach of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Commercial Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Content Marketer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Data Analytics Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Marketing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Female Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Humanitarian Philanthropy Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Business Idea of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Integrated Marketing Communications Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Strategist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Media &amp; Advertising Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sales Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sustainability Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>VP of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>Market Research</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Expert of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Research Services Organisation of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Researcher of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Supply Chain Professional of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}><strong>Ready to Shine Your Social Media Brilliance?</strong></h4>
                        <p>Let's celebrate your achievements together at this Social Media Awards!</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now!</a></p>
                        <p>Learn More&nbsp;About the <a href="https://forttuna.co/awards/business-awards/">Business awards</a>.</p>
                        <p>Know About <a href="https://forttuna.co/women-in-tech-awards/">Women In Tech Award</a>.</p>

                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
