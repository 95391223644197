import React from 'react'
import bg from '../../images/background/pattern-6.png'
import bg2 from '../../images/background/pattern-10.png'
import { Link } from 'react-router-dom'

const WhyAttendS2 = (props) => {

    const Service = [
        {
            title: 'Global Participation',
            des: 'The Forttuna Global Excellence Awards Program is a premier international platform recognizing exceptional achievements across industries. With participation from 81+ countries, it celebrates global innovation, leadership, and excellence. Honoring trailblazers worldwide, the program unites visionaries, showcasing the transformative power of excellence on an international stage.',
            icon: 'flaticon-planet-earth'
        },
        {
            title: 'Networking Opportunity',
            des: 'The Forttuna Global Excellence Awards Program goes beyond being just a recognition ceremony—it is a distinguished platform for meaningful networking and collaboration. This program brings together government officials, high-net-worth individuals (HNIs), innovators, industry professionals, organizations, and thought leaders. It creates a unique environment for exchanging ideas, sharing insights, and building partnerships that transcend industries and borders, fostering impactful connections and driving collective progress.',
            icon: 'flaticon-connection'
        },
        {
            title: 'Stellar Lineup',
            des: 'Join an exclusive network of industry pioneers, changemakers, and visionaries honored by the Forttuna Global Excellence Awards Program. Elevate your profile, gain international acclaim, and become part of a prestigious community fueled by innovation and excellence.',
            icon: 'flaticon-user-1'
        },
        {
            title: 'Extensive PR Coverage',
            des: 'The Forttuna Global Excellence Awards Program has earned widespread recognition through extensive media coverage, featuring prominently in prestigious global publications such as Forbes India, Khaleej Times, Gulf News, Business Standard, Bloomberg and many more. These platforms have highlighted Forttuna’s unwavering commitment to celebrating achievements and promoting a culture of excellence while spotlighting the groundbreaking innovations and transformative contributions of its awardees to their respective industries.',
            icon: 'flaticon-video-camera'
        },
        {
            title: 'Unmatched Recognition',
            des: 'Winners of the Forttuna Global Excellence Awards are not only honored with a distinguished recognition but also enjoy heightened public visibility, solidifying their status as trailblazers and visionaries within the healthcare industry.',
            icon: 'flaticon-trophy-3'
        },
        {
            title: 'International Jury',
            des: 'The International Jury serves as the foundation of Forttuna\'s dedication to celebrating global excellence. Consisting of esteemed professionals, industry pioneers, and subject matter experts from diverse fields, the jury brings unparalleled expertise and varied perspectives to the evaluation process. Among them are award-winning serial entrepreneurs, an advisor to the President of the United States, Padma Shri honorees, and many other distinguished figures from around the globe, reflecting the truly international and elite nature of our program.',
            icon: 'flaticon-group'
        },
    ]

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section className="services-one">
            {/* <div className="services-one_pattern-one" style={{ backgroundImage: `url(${bg})` }}></div>
            <div className="services-one_pattern-two" style={{ backgroundImage: `url(${bg2})` }}></div> */}
            <div className="auto-container">
                <div className="sec-title centered title-anim">
                    {/* <div className="sec-title_title">Join The Event</div> */}
                    <h2 className="sec-title_heading">Why Forttuna Awards?</h2>
                </div>
                <div className="row clearfix">
                    {Service.map((service, sitem) => (
                        <div className="service-block_one col-lg-4 col-md-6 col-sm-12" key={sitem}>
                            <div className="service-block_one-inner" style={{height: '585px'}}>
                                <div className={`service-block_one-icon fi ${service.icon}`}></div>
                                <h4 className="service-block_one-heading" style={{color: '#c88c19'}}>{service.title}</h4>
                                <div className="service-block_one-text">{service.des}</div>
                                {/* <a href="" className="service-block_one-arrow fi flaticon-next-2"></a> */}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>

    )
}

export default WhyAttendS2;