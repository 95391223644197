import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards : HR Excellence Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Celebrate outstanding achievements in HR with the Forttuna Global HR Excellence Awards. Gain global recognition and connect with industry leaders!" />
            <meta property="og:title" content="The Forttuna Global Excellence Awards : HR Excellence Awards" />
            <meta property="og:description" content="Celebrate outstanding achievements in HR with the Forttuna Global HR Excellence Awards. Gain global recognition and connect with industry leaders!" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="hr excellence awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards : HR Excellence Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="revered hr excellence awards" />
                        </div>
                        <h2 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: HR Excellence Awards</strong></h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led HR Excellence Awards: Be Recognized on the Global Stage</strong></h1>
                        <p>
                            The HR Excellence Awards category of Forttuna Global Excellence Awards shines a spotlight on outstanding individuals who are transforming the HR field. These professionals, often working diligently behind the scenes. They are essential
                            when it comes to molding company culture, encouraging innovation, and drawing in top talent. Hence, their commitment merits global recognition.
                        </p>
                        <p>
                            We honor their <a href="https://www.indeed.com/hire/c/info/hr-leadership">leadership</a>, inventive strategies, and significant contributions that propel success not only within their organizations but throughout the industry as a
                            whole.
                        </p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global HR Excellence Awards?</strong></h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Jury-Driven HR Honors:</strong> Evaluates on merit, celebrating outstanding accomplishments across the HR industry.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>World-Class HR Accolade:</strong> Acknowledges notable impact in HR worldwide.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global HR Collaboration:</strong> Beyond being a global <a href="https://forttuna.co/">recognition platform</a>, it offers an opportunity to network and collaborate with HR leaders and innovators from diverse sectors.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in the HR Excellence Awards?</strong></h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Shine a global spotlight on your HR innovations.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recognize and honor exceptional personal achievements in the industry.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Connect with top HR professionals at our prestigious awards event.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Enhance your brand&rsquo;s visibility and unleash new opportunities.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Be in the limelight with news coverage.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Benefit from an impartial assessment by a panel of esteemed international experts.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>The Journey to Recognition: Participation Process</h3>
                        <p><strong>Step 1: Initial Assessment</strong> Potential participants undergo an interview to evaluate their fittingness.</p>
                        <p><strong>Step 2: Formal Application</strong> Successful candidates proceed to submit a formal participation application.</p>
                        <p><strong>Step 3: Detailed Evaluation</strong> Selected participants are required to complete a comprehensive questionnaire.</p>
                        <p><strong>Step 4: Jury Decision</strong> The esteemed jury reviews the questionnaires and selects the final participants.</p>
                        <h3><strong>Categories HR Excellence Awards:</strong></h3>
                        <h4><strong>HR Excellence Business Leaders Category Awards</strong></h4>
                        <p><strong>A. Human Resources</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leadership Educator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Leader &amp; Woman Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Outsourcing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Outsourcing Woman Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influential Talent Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Talent Acquisition Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>HR Excellence Health &amp; Wellness Leaders Category Awards</strong></p>
                        <p><strong>A. Individual Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to Shine Your HR Brilliance at the HR Excellence awards?</h4>
                        <p>Let's celebrate your achievements together at this HR Excellence awards!</p>
                        <p><a href="https://participate.forttuna.co/">Participate Now!</a></p>
                        <p><a href="https://forttuna.co/awards/corporate-awards/">Learn More</a>&nbsp;About the Corporate Award.</p>
                        <p>Know About <a href="https://forttuna.co/tech-awards/">Tech Awards</a>.</p>
                        <h3 style={{color: '#fff'}}><strong>Forttuna Global Excellence Awards</strong></h3>
                        <p>
                            The <a href="https://www.forttuna.co/">**Forttuna Global Excellence Awards</a>** revere extraordinary business leaders and organizations. Save the dates so you don&rsquo;t miss out these upcoming award ceremonies:
                            <strong>6th and 7th, of December 2024 in Dubai</strong> and <strong>June 2025, in Mumbai.</strong> Outstanding leaders and their achievements across diverse industries will be the highlight of this prestigious event.</p>

                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
