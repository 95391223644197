import timg1 from '../images/resource/moran-cerf.webp'
import timg2 from '../images/resource/Prof.-Sandra-Matz.jpg'


import tsimg1 from '../images/resource/moran-cerf.webp'
import tsimg2 from '../images/resource/Prof.-Sandra-Matz.jpg'


const Teams = [
   {
      Id: '2',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Mora Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      keynotelink: 'https://www.youtube.com/watch?v=4VxVN_rj7E4',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '3',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Prof. Dr. Sandra Matz',
      slug: 'https://linkedin.com/in/sandra-matz-6824742b',
      keynotelink: 'https://www.youtube.com/watch?v=4VxVN_rj7E4',
      title: 'Professor, Columbia Business School, USA',
   },
]

export default Teams;