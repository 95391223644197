import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Recognition Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Join the top recognition awards, where outstanding companies and leaders receive international recognition. Participate today and elevate your brand image." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards: Recognition Awards" />
            <meta property="og:description" content="Join the top recognition awards, where outstanding companies and leaders receive international recognition. Participate today and elevate your brand image." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="recognition awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Recognition Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="top recognition awards" />
                        </div>
                        <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards: Recognition Awards</h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}>First-Ever Jury-Led Recognition Awards: Be Recognized on the Global Stage</h1>
                        <p>The Recognition Awards recognize and honor exceptional companies transforming the global business arena. Whether leading exporters or disruptive startups, all can showcase their excellence and innovation.</p>
                        <p>
                            Forttuna Global Excellence Awards is one such reputed platform that shines a spotlight on your achievements internationally. Earning a recognition award can drastically shape your impact on international trade. The best part is that you
                            become a part of the community of visionary leaders driving the future of business.
                        </p>
                        <h3>What are the Forttuna Global Excellence Recognition Awards?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Recognition:</strong> Showcases exceptional achievements of leaders and their brands on the global stage.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Ingenious</strong> <strong>Collaboration:</strong> Promotes connections and collaborations for a thriving global business landscape.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Expert Validation:</strong> Provides recognition from a distinguished panel of industry leaders.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Impact on the Future of Trade:</strong> Highlights the contributions to the evolution of <a href="https://www.gelato.com/in/blog/international-commerce">international commerce.</a></li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in the</strong> Recognition Awards?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Achieve global recognition for your <a href="https://www.forttuna.co/business-leaders-edition">company's</a> distinctive ideas and leadership with the Recognition Awards.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Highlight your team&rsquo;s talents and inspire others by showcasing your accomplishments.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Connect with industry leaders at this esteemed event, and boost your networking opportunities.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Amplify your brand&rsquo;s visibility and attract new prospects through international recognition.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Be at the center stage with high media coverage. Elevate your achievements and position yourself as a leader.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Benefit from a fair and merit-based assessment by a prestigious panel of international experts.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Recognition Awards Categories:</h3>
                        <h3 style={{color: '#fff'}}>Recognition Awards For Business Leaders:</h3>
                        <p><strong>A. Architecture, Construction &amp; Engineering</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Eco-System Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Construction Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Interior Designer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Legal Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Company of the Year</li>
                        </ol>
                        <p><strong>B. Automobile</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sales Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategist of the Year</li>
                        </ol>
                        <p><strong>C. Aviation</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aviation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Procurement Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        </ol>
                        <p><strong>D. Education</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Learning Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Co-Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Compliance Training Company of the Year (edtech)</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Privacy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Deputy Vice-Chancellor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of Music of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Education Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Education Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Educator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Author of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur in Higher Education of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Enrollment Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Female Publisher of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Human-Centered Design Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leadership Development Program of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator Facilitator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Learning &amp; Development Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Legal Advocacy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award In Leadership Development</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement in Higher Education Management</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Influential Young Educator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Inspiring Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Inspiring Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Non-Profit of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Personal Leadership Executive Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Quality Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Innovation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic &amp; Visionary Leader of the Year in Higher Education</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Well-being &amp; Human Flourishing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Women Leader of the Year in Social Impact</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>E. Entertainment and Media</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Animation and Visual Effects Community Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Journalist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Content Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Film and Television Production Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Presenter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Travel Influencer of the Year</li>
                        </ol>
                        <p><strong>F. Fashion and Beauty</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Board Member of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cultural Heritage Fashion Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CX Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Innovation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fashion Brand of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fragrance Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Haircare Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Business Development Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>G. Finance and Insurance</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Banking Professional of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cash Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Compliance Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Finance Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Finance Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>FinTech Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Impact Investor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Investment Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Regional Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wealth Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>H. Governance</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Leader of the Year - Government Services</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        </ol>
                        <p><strong>I. Hospitality</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Facility Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Travel Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Travel Professional of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        </ol>
                        <p><strong>I. Human Resources</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leadership Educator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Leader &amp; Woman Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Outsourcing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR Outsourcing Woman Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influential Talent Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Talent Acquisition Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>J. Law</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Corporate Lawyer of the Year</li>
                        </ol>
                        <p><strong>K. Manufacturing</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        </ol>
                        <p><strong>L. Market Research</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Expert of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Research Services Organisation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Researcher of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Supply Chain Professional of the Year</li>
                        </ol>
                        <p><strong>M. Marketing</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Advertising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategy Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Commercial Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Content Marketer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Analytics Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Female Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Humanitarian Philanthropy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Business Idea of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Integrated Marketing Communications Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Media &amp; Advertising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sales Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Media Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sustainability Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>VP of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>N. Real Estate</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Strategist of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Marketer of the Year</li>
                        </ol>
                        <p><strong>O. SME&rsquo;s</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Competency Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Consultancy of the Year (Outsourcing Services)</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Evangelist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Operations</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Managing Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pastry Chef of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Patisserie Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>SME of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Women Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>P. Technology</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Ad-Tech Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>AVP of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Blockchain Consultant of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Transformation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Interoperability Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Revenue Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cloud Computing Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Developer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Business of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Center Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Disruptor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Technology Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Transformation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Technology Advocate of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Icon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>IT Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Agriculture and Development</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Achievement Award in Engineering and Manufacturing</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Tech Innovator Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Power and Water Utilities Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sales and Marketing Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Cloud Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Utility Industry Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Website Design &amp; Development Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Technology Leader of the Year</li>
                        </ol>
                        <h3>Recognition Awards For <strong>Health &amp; Wellness Leaders</strong></h3>
                        <p><strong>A. AYUSH / Spa / Wellness / Yoga Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Wellness Brand of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Energy Medicine Practitioner of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year Health and Wellness</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Holistic Healer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Holistic Nutrition and Lifestyle Program of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Product of the Year in AYUSH</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Centre/Retreat of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Yoga Coach of the Year</li>
                        </ol>
                        <p><strong>B. Company and Brands</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>AI Dermatology Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Anti-Aging Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Care Home Pharmacy Services Provider of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dental Clinic of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Tech Company of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Femtech&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Consultancy of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Idea of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Medical Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Long-Term Care Provider of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Equipment Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Tourism Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Natural Ingredient Skincare Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Nursing Recruitment Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Distributor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Innovation Organisation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharmacy Retail Chain of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Product of the Year - Contraceptive</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Protein Snack of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Public Eye Health Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Public Healthcare Initiative of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Stress Management Organisation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Well-being Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Product of the Year</li>
                        </ol>
                        <p><strong>C. CXO's - Health &amp; Wellness</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Audit Executive (CAE) of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Product Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CISO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young CEO of the Year</li>
                        </ol>
                        <p><strong>D. Hospital &amp; Clinic Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic/Cosmetic Surgery Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Clinic Chain of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetic Dermatology Clinic of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Rehabilitation Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Eye Hospital of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fertility Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Hair Clinic of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Hospital of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Pain Management Program of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Longevity Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Tech Organization of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health care Center of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Program of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Quality and Patient Safety Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Single Specialty Hospital of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Women Surgical Clinic of the Year</li>
                        </ol>
                        <p><strong>E. Individual Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic Consultant of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetics Skincare Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>AI Leader of the Year in Health and Social&nbsp;Care</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Animal Advocate of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Ayurvedic Physician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Behaviour Analyst of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Bio-Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiothoracic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Care Services Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Child Advocacy &amp; Therapy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chiropractor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chronic Condition and Wellbeing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Clinical Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Community Health Impact Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Constructive Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Consultant Psychiatrist and Senior Physician Executive of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetic Plastic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Counsellor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dentist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dermatologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Diabetologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dietician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Restorative Dentist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Diversity Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Educator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emergency Physician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Leader of the Year in Home Care</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Medical Communications Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Executive Healthcare Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Executive Women of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Eye Care Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Facial Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>FemTech Platform of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fitness Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fitness Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fitness Solution Expert of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year in Wellness</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fundraising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>General Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Global Impact Leader of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Growth and Expansion Leader of the Year in Home Care</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Gynecologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Health &amp; Wellness Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Leader of the Year in Chiropractic</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Health Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Compliance Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Education Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Finance Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Strategy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Mentor Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Regulatory Affairs Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Researcher of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Staffing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Technology Integration Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Technology Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Infertility Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Health and Well-being Programme of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year in Gynecology and Oncology</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Mental Health Idea of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Product Distributor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Solutions Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Integrative Health Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lab Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Natural Medicine</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Managing Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Maxillofacial Prosthodontist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Device Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>MedTech Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Professional of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Promoter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year in Quality</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Metaphysician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Midwife of the Year (Wellness and Holistic Care)</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Minimally Invasive Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Molecular Biologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Inspiring Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Neurologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Neuroscience Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Nursing Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Nutritionist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Ophthalmologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Optometrist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Oral Health Promoter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopaedic Sports Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopedic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Osteopath of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pathologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Patient-Centered Innovation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Physician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Physiotherapist of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Plastic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Podiatrist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Posthumous - Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Postural Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>President of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Psychologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Psychotherapist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Public Health Specialist of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Restorative Dentist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Skin Cancer Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Skincare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Skincare Training and Education Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Change Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Speech Language Pathologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Spiritual Education Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Stress Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Supply Chain Professional of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sustainability Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Leader of the Year in Healthcare and Wellness</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Urologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Women of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Co-Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Wellness Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Wellness Visionary of the Year</li>
                        </ol>
                        <p><strong>F. Medical College / Institute Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Clinical Research Innovation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Education and Placement Leader of the Year</li>
                        </ol>
                        <p><strong>G. Wellness and Skincare</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Skincare Product of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to shine a light at the top Recognition Awards?</h4>
                        <p>Showcase your impact on global trade and network with top industry leaders who are defining the future of international business at the Recognition Awards!</p>
                        <p>Gain Recognition! <a href="https://participate.forttuna.co/">Participate Now!</a></p>
                        <p>Know About <a href="https://forttuna.co/awards/business-awards/">Business Awards.</a></p>
                        <p>Learn More on <a href="https://forttuna.co/awards-for-doctors-in-india/">Awards for Doctors in India.</a></p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
