import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Tech Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Celebrate innovation at the Forttuna Tech Awards. Join us to honor trailblazers in technology and gain global recognition for your achievements." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards: Tech Awards" />
            <meta property="og:description" content="Celebrate innovation at the Forttuna Tech Awards. Join us to honor trailblazers in technology and gain global recognition for your achievements." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="Tech awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Tech Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Tech awards ceremony" />
                        </div>
                        <h2 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Tech Awards</strong></h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Tech Awards: Be Recognized on the World Stage</strong></h1>
                        <p>The Tech awards are to praise the trailblazers, paving the way for a better future. Visionary <a href="https://www.forbes.com/councils/forbestechcouncil/2024/01/09/tech-industry-innovators-20-common-compelling-keys-to-their-success/">innovators</a> are shaping the dynamic world of technology. They challenge the limits of what can be achieved.</p>
                        <p>The Forttuna Global Excellence Awards -Tech Awards are designed to honor phenomenal individuals and teams. The extraordinary achievements and significant influence are celebrated by offering center stage to recognize tech triumphs.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna</strong> Global Excellence <strong>Tech Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Merit-Based Honor:</strong> An esteemed, jury-led award program based on merit.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Pioneering Award</strong>: For pioneering new technologies and disruptive solutions.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Impact Celebration:</strong> Establishes leadership position and innovative capabilities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Industry Networking Platform:</strong> Connects with influential peers and potential partners.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in Tech Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Secure global recognition for your tech innovation.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Highlight and inspire through individual and team achievements.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Network with tech titans at our prestigious tech <a href="https://www.forttuna.co/">awards</a> event.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Capture the limelight with substantial media exposure.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Be a part of an incredible experience with a fair evaluation from a renowned jury.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Categories for Tech Awards:</h3>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ad-Tech Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AVP of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Blockchain Consultant of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Transformation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Interoperability Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Revenue Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cloud Computing Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Developer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Business of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Data Center Management Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Disruptor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Technology Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Transformation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Technology Advocate of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Icon of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>IT Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Agriculture and Development</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Achievement Award in Engineering and Manufacturing</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Tech Innovator Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Power and Water Utilities Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sales and Marketing Leader of the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Scientist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Cloud Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Utility Industry Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Website Design &amp; Development Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Technology Leader of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to shine at the Tech Awards?</h4>
                        <p>Be a Part of the Future of Tech. <a href="https://participate.forttuna.co/">Participate Now!</a></p>
                        <p>Learn More&nbsp;About the <a href="https://forttuna.co/uk-tech-awards/">UK Tech Awards</a>.</p>
                        <p>Know About <a href="https://forttuna.co/hr-excellence-awards/">HR Excellence Awards</a>.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
