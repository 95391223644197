import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import { Link } from 'react-router-dom'
import pIcon1 from '../../images/background/Awards.jpg';
import eli from '../../images/partner/2024-54.png';
import us from '../../images/partner/2024-53.png';
import prime from '../../images/partner/2024-55.png';
import Navbar from '../../components/Navbar/Navbar';
import VideoArea from '../../components/VideoArea/VideoArea';
import OurPartner from '../../components/OurPartner/OurPartnerbusniess.js';
import OurPartner2 from '../../components/OurPartner2/OurPartnerbusniess2.js';
import OurPartner3 from '../../components/OurPartner3/OurPartnerbusniess3.js';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
import Scrollbar from '../../components/scrollbar/scrollbar';

const HomePage = () => {
    useEffect(() => {
        document.title = 'Forttuna Global Excellence Awards | Our Partners';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Forttuna Global Excellence Awards invites industry leaders to celebrate exceptional achievements across various industries. Sign up today!" />
            <meta property="og:title" content="Forttuna Global Excellence Awards | Our Partners" />
            <meta property="og:description" content="Forttuna Global Excellence Awards invites industry leaders to celebrate exceptional achievements across various industries. Sign up today!" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1} />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar />
            <section className="page-title" style={{ backgroundImage: `url(${pIcon1})` }}>
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="left-box">
                            <div className="page-title_big">Partners</div>
                            <h2 className="page-title_heading"> Partners</h2>
                        </div>
                        {/* <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Home</Link></li>
                            <li>Our Partner</li>
                        </ul> */}
                    </div>
                </div>
            </section>
            <section className="" style={{margin: '45px'}}>
                <div className="auto-container">
                    <div className='row'>
                        <div className="col-lg-12">
                            <div class="business-one_button">
                                <a class="theme-btn btn-style-one" href="/contacts/">
                                    <span class="btn-wrap"><span class="text-one">Become A Partner</span><span class="text-two">Become A Partner</span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="partner-one"  style={{marginTop: '40px'}}>
                <div className="auto-container">
                    <div className="sec-title title-anim centered">
                        <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18', fontSize: '30px', marginBottom: '-20px' }}> Hospitality Partner</h2>
                    </div>
                        {/* <h2 className="sec-title_heading">Partners</h2> */}
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="partner-one_column col-lg-2 col-md-2 col-sm-2">
                                    <div  style={{ padding: '13x', }}>
                                        <img src={eli} alt="" style={{width:'170px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="partner-one"  style={{marginTop: '40px'}}>
                <div className="auto-container">
                    <div className="sec-title title-anim centered">
                        <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18', fontSize: '30px', marginBottom: '-20px' }}> Production Partner</h2>
                    </div>
                        {/* <h2 className="sec-title_heading">Partners</h2> */}
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="partner-one_column col-lg-2 col-md-2 col-sm-2">
                                    <div  style={{ padding: '13x', }}>
                                        <img src={us} alt="" style={{width:'170px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="partner-one"  style={{marginTop: '40px'}}>
                <div className="auto-container">
                    <div className="sec-title title-anim centered">
                        <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18', fontSize: '30px', marginBottom: '-20px' }}> Media Partner</h2>
                    </div>
                        {/* <h2 className="sec-title_heading">Partners</h2> */}
                    <div className="row clearfix">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="partner-one_column col-lg-2 col-md-2 col-sm-2">
                                    <div  style={{ padding: '13x', }}>
                                        <img src={prime} alt="" style={{width:'170px'}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <OurPartner />
            <OurPartner2 />
            <OurPartner3 />
            <VideoArea />
            <Subscribe2 />
            <Footer2 />
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default HomePage;