import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Women In Tech Awards';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Honor the achievements of women in tech with the Forttuna Women In Tech Awards. Pave way for the next generation of female tech leaders and entrepreneurs." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards: Women In Tech Awards" />
            <meta property="og:description" content="Honor the achievements of women in tech with the Forttuna Women In Tech Awards. Pave way for the next generation of female tech leaders and entrepreneurs." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="women in tech awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Women In Tech Awards' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna women in tech awards" />
                        </div>
                        <h2 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Women In Tech Awards</strong></h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Women In Tech Awards: Be Recognized on the World Stage</strong></h1>
                        <p>Women in technology deserves honorable accolades like Forttuna Women In Tech Awards. The business world thrives on innovation, resilience, and leadership &ndash; qualities that women in tech consistently bring to the table.</p>
                        <p>The Forttuna Global Excellence Awards celebrates these outstanding contributions and the significant impact women have in the tech industry. This global recognition will inspire and pave the way for the next generation of <a href="https://www.womentech.net/en-in/women-in-tech-to-watch">female tech leaders</a> and entrepreneurs.</p>
                        <h3 style={{color: '#fff'}}><strong>What are the Forttuna Global Excellence Women In Tech Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Women in Tech Awards:</strong> A prestigious, jury-led, and merit-based awards program recognizing outstanding achievements across various industries.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Tech Impact Honors:</strong> Recognizes <a href="https://www.forttuna.co/business-leaders-edition-india/">individuals</a> and companies through the Women in Tech Awards, celebrating significant impacts on the global landscape.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Tech Leader Networking Platform:</strong> More than just awards, it is a unique opportunity to connect and collaborate with skillful professionals in tech industry.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in Women In Tech Awards?</strong></h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Accomplish international recognition for your company&rsquo;s tech innovative and leadership.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Demonstrate your sole talent and inspire others through your efforts.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Network with industry leaders at our prestigious women in technology awards ceremony.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Enhance your brand profile and attract new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Be the limelight of news coverage.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Get validation from a trustworthy evaluation from a distinguished panel of international experts.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Women In Tech Awards Categories:</strong></h3>
                        <p>The tech category has dedicated award for women-</p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        </ol>
                        <p>Other awards in Tech include-</p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ad-Tech Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AVP of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Blockchain Consultant of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Transformation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Interoperability Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Revenue Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cloud Computing Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Developer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Business of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Data Center Management Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Disruptor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Technology Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Transformation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Technology Advocate of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Icon of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>IT Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Agriculture and Development</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Achievement Award in Engineering and Manufacturing</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Tech Innovator Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Power and Water Utilities Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sales and Marketing Leader of the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Scientist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Cloud Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Utility Industry Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Website Design &amp; Development Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Technology Leader of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to Illuminate the Tech World?</h4>
                        <p>Let's celebrate your achievements together at this Women In Tech Awards!</p>
                        <p><a href="https://participate.forttuna.co/">Join us</a>!</p>
                        <p>Learn More&nbsp;About the <a href="https://forttuna.co/awards/hospitality-awards/">Hospitality Award</a>.</p>
                        <p>Know About <a href="https://forttuna.co/social-media-awards/">Social Media Awards.</a></p>

                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
