import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import shape1 from '../../images/background/pattern-24.png'
import classnames from 'classnames';
import { Link } from 'react-router-dom'
import Events from '../../api/Events';

const EventsSection3 = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    return (
        <section className="speakers-four" id='events'>
            <div className="speakers-four_pattern" style={{ backgroundImage: `url(${shape1})` }}></div>
            <div className="auto-container">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'>
                        <div className="sec-title" style={{textAlign: 'center', marginBottom: '180px'}}>
                            <div className="sec-title_title">Agenda</div>
                            <h2 className="sec-title_heading" style={{textAalign: 'left'}}>2025 Global Edition Health & Wellness Leaders</h2>
                        </div>
                    </TabPane>
                </TabContent>
                <div className="inner-container">
                    <div className="speaker-info-tabs">
                        <div className="speaker-tabs_two tabs-box">
                            {/* <ul>
                                <Nav tabs className="tab-btns tab-buttons clearfix">
                                    <NavItem className='tab-btn'>
                                        <NavLink
                                            className={classnames({ active: activeTab === "3" })}
                                            onClick={() => { toggle("1"); }}
                                        >
                                            <span>India &nbsp; &nbsp;&nbsp;</span>Jun <strong>00</strong><i>2025</i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='tab-btn'>
                                        <NavLink
                                            className={classnames({ active: activeTab === "4" })}
                                            onClick={() => { toggle("2"); }}
                                        >
                                            <span>India &nbsp; &nbsp;&nbsp;</span>jun <strong>00</strong><i>2025</i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='tab-btn'>
                                        <NavLink
                                            className={classnames({ active: activeTab === "1" })}
                                            onClick={() => { toggle("3"); }}
                                        >
                                            <span>Global</span> dec <strong>00</strong><i>2025</i>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className='tab-btn'>
                                        <NavLink
                                            className={classnames({ active: activeTab === "2" })}
                                            onClick={() => { toggle("4"); }}
                                        >
                                            <span>Global</span>Dec <strong>00</strong><i>2025</i>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </ul> */}
                            <div className="tabs-content">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId='1'>
                                        {Events.slice(0, 6).map((event, i) => (
                                            <div className="content" key={i}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location" style={{paddingRight: '115px', fontSize: '15px'}}>{event.des}</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId='2'>
                                        {Events.slice(0, 6).map((event, i) => (
                                            <div className="content" key={i}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location">{event.des}</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId='3'>
                                        {Events.slice(0, 6).map((event, i) => (
                                            <div className="content" key={i}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location">{event.des}</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId='4'>
                                        {Events.slice(0, 6).map((event, i) => (
                                            <div className="content" key={i}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location">{event.des}</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EventsSection3;



{/* <TabPane tabId='2'>
                                        {Events.slice(2, 6).map((event, eb2) => (
                                            <div className="content" key={eb2}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId='3'>
                                        {Events.slice(1, 6).map((event, eb3) => (
                                            <div className="content" key={eb3}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId='4'>
                                        {Events.slice(1, 6).map((event, eb3) => (
                                            <div className="content" key={eb3}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId='5'>
                                        {Events.slice(1, 6).map((event, eb3) => (
                                            <div className="content" key={eb3}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId='6'>
                                        {Events.slice(1, 6).map((event, eb3) => (
                                            <div className="content" key={eb3}>
                                                <div className="leadership-three_block" >
                                                    <div className="leadership-three_block-inner">
                                                        <div className="leadership-three_block-content">
                                                            <div className="leadership-three_block-image">
                                                                <img src={event.eImg} alt="" />
                                                            </div>
                                                            <div className="leadership-three_block-name">{event.speaker} </div>
                                                            <h3 className="leadership-three_block-title" style={{color: '#fff'}}>{event.eTitle}</h3>
                                                            <div className="leadership-three_block-location"><span>Location:</span> Hall 1, Building A , Golden Street , Southafrica</div>
                                                            <div className="leadership-three_block-timing"><span>{event.time}</span></div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane> */}