import React from 'react';
import { Link } from 'react-router-dom'
import blogtp from '../../images/resource/news-17.jpg'
import blogtp2 from '../../images/resource/author-5.jpg'
import blog1 from '../../images/resource/author-1.jpg'
import blog2 from '../../images/resource/author-2.jpg'
import blog3 from '../../images/resource/author-3.jpg'
// import blogs from '../../api/blogs';
import blogSingleImg1 from "../../images/resource/news-15.jpg"
import { useParams } from 'react-router-dom'
// import BlogSidebar from '../BlogSidebar';

const BlogSingle = (props) => {

    // const { slug } = useParams()

    // const BlogDetails = blogs.find(item => item.slug === slug)

    // const submitHandler = (e) => {
    //     e.preventDefault()
    // }

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    return (
        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="content-side col-lg-12 col-md-12 col-sm-12">
                        <div className="blog-detail">
                            <div className="blog-detail_inner">
                                <div className="blog-detail_image">
                                    <img src={blogSingleImg1} alt=" Global Education Awards For Recognition " />
                                </div>
                                <div className="blog-detail_content">
                                    <div className="blog-detail_date">22 Nov 2024</div>
                                    <ul className="blog-detail_meta">
                                        <li style={{ listStyle: 'disc' }} ><span className="icon fa fa-regular fa-calendar"></span>by Forttuna Awards</li>
                                    </ul>
                                    <h1 style={{fontSize :'40px' , lineHeight: '43px'}}>Top 5 Global Education Awards: Inspiring the Next Generation</h1>
                                    <p><strong>slug-</strong> top-global-education-awards</p>
                                    <p>
                                        Global Education Awards presents deserving people in the globe education. It celebrates notable contributions and achievements made by people around the world for education. Today, education has successfully reached the roots of
                                        progress and innovation in such a globalized world. It means not only providing knowledge but inspiring future leaders, thinkers, and innovators.
                                    </p>
                                    <p>
                                        Global education awards recognize success, ingenuity, and resilience in every space of education, be it teaching methods, innovative research, or something more. They not only acknowledge the winners but also inspire many others to
                                        reach for greatness in their work in education.
                                    </p>
                                    <h2>List Of Top 5 Global Education Awards:</h2>
                                    <p>Let's explore some of the most prestigious Global Education Awards that are shaping the future of education and inspiring the next generation of high achievers.</p>
                                    <h3>1. Forttuna Global Excellence Awards: Celebrating Education Achievements</h3>
                                    <p>
                                        The <a href="https://www.forttuna.co/"><strong>Fortuna Global Excellence Awards</strong></a> acknowledge and celebrate great feats in many fields, including education. Redefining the landscape of educational recognition, the Fortuna
                                        Excellence Award Ceremony is a celebration of excellence.
                                    </p>
                                    <p>
                                        It is the strictest platform that celebrates true innovations with outstanding contributions to the field of education and disregards the traditional "pay-to-play" model. only honors individuals and organizations that have made
                                        significant contributions to the field of education.
                                    </p>
                                    <h3><strong>A Wide Range of Education Award Categories</strong></h3>
                                    <p>The Forttuna Global Excellence Awards offer a <a href="https://forttuna.co/business-leaders-edition/">diverse range of categories</a> specifically dedicated to recognizing excellence in education. Some of the key categories include:</p>
                                    <ol>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Business Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Business Woman of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CEO of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CFO of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Chief Learning Officer of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CIO of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Co-Founder of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Compliance Training Company of the Year (edtech)</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>COO of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CTO of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Data Privacy Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Deputy Vice-Chancellor of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Director of Music of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Director of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Education Company of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Education Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Educator of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Emerging Author of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Emerging Entrepreneur in Higher Education of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Enrollment Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Entrepreneur of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Female Publisher of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Human-Centered Design Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Influencer of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovative Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovative Leadership Development Program of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovator Facilitator of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Learning &amp; Development Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Legal Advocacy Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Lifetime Achievement Award</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Lifetime Achievement Award In Leadership Development</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Lifetime Achievement in Higher Education Management</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Mentor of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Influential Young Educator of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Innovative Business Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Innovative Leader of the&nbsp;Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Inspiring Business Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Inspiring Leader of the&nbsp;Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Non-Profit of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Personal Leadership Executive Coach of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Quality Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Social Innovation Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Start-up of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Strategic &amp; Visionary Leader of the Year in Higher Education</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Strategic Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Strategist of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Visionary Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Visionary of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Well-being &amp; Human Flourishing Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Women Leader of the Year in Social Impact</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Young Business Leader of the Year</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Young Entrepreneur of the Year</li>
                                    </ol>
                                    <p>The Forttuna Global Excellence Awards inspires innovation, excellence, and positive change in the education sector by honoring and rewarding these shining stars and organizations.</p>
                                    <h3>2. The Global Teacher Prize: Honoring Exceptional Educators</h3>
                                    <h3>Celebrating Teaching Excellence Worldwide</h3>
                                    <p>
                                        Often referred to as the "Nobel Prize of Teaching," the Global Teacher Prize is a yearly US $1 million prize by the Varkey Foundation. This is one of the most premium education awards bestowed worldwide on a spectacular teacher who has
                                        made a tremendous difference to the profession.
                                    </p>
                                    <h3>Impact on Education</h3>
                                    <ul>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>It raises the value of the teaching profession</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>It puts before the world good practices on how to educate</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Provokes educators to think out of the square in their classroom</li>
                                    </ul>
                                    <h3>3. The Yidan Prize: Transforming Education for the Future</h3>
                                    <h3>Inclusive Excellence in Education</h3>
                                    <p>
                                        Established in 2016, the Yidan Prize is identified as one of the world's most prestigious international awards in education. It has two annual prizes: the Yidan Prize for Education Research and the Yidan Prize for Education Development.
                                    </p>
                                    <h3>Focus Areas</h3>
                                    <ol>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Education Research: For pioneering research that will shape the future of education</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Education Development: Commending innovative initiatives that overcome burning issues in education</li>
                                    </ol>
                                    <h4>4. WISE Prize for Education: Innovative Solutions for Global Challenges</h4>
                                    <h3>Innovation in Education</h3>
                                    <p>
                                        The WISE Prize for Education, awarded by the <a href="https://www.qf.org.qa/">Qatar Foundation</a>, is accorded to an individual or a group of persons for any outstanding contribution to education. It also serves on the list of
                                        international awards in education because it focuses on innovation and practical solutions to problems in education.
                                    </p>
                                    <p>The WISE Prize honors educational efforts from various backgrounds, and solutions achieved and adapted in one context can be applied in other cultural and socioeconomic contexts.</p>
                                    <h2>5. UNESCO-Japan Prize on Education for Sustainable Development</h2>
                                    <h3>Education in the Pursuit of Sustainability</h3>
                                    <p>
                                        The UNESCO-Japan Prize on Education for Sustainable Development promotes excellence in, and encourages innovation in, Education for Sustainable Development. The prize addresses best practices in ESD worldwide, based on their relevance,
                                        impact, and sustainability.
                                    </p>
                                    <p>The UNESCO-Japan Prize harmonizes sustainability within education curricula worldwide and equips learners to emerge and face global challenges with confidence.</p>
                                    <h2>Conclusion: Inspiring Excellence in Global Education</h2>
                                    <p>The Global Education Awards discussed above represent a small fraction of the global recognitions regarding education. Awards and recognitions such as these serve to:</p>
                                    <ol>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Commemorate excellence and exemplary educational successes</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Challenge educators and researchers into breaking boundaries</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovative modes of teaching and learning shall be encouraged</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>International cooperation for education shall be promoted</li>
                                        <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Urgent problems in education worldwide shall be addressed</li>
                                    </ol>
                                    <p>
                                        Quality education cannot be a luxury where grave problems are experienced alike around the world. Awards also shine lights on individual achievement but, at the same time, shine a light to the promise of transformation that education
                                        holds.
                                    </p>
                                    <p>
                                        These Global Education Awards, of course are an interest even of utmost importance to top achievers in different industry spheres-from the leaders and entrepreneurs of the corporate world, industrial leaders, leading doctors,
                                        researchers, or famous artists. They remind them of the need to innovate and be the very best in terms of education, whether their background is as a leading doctor, researcher, or artist.
                                    </p>
                                    <p>
                                        It is through acknowledgment and further support toward educational excellence that we bring forward to our global society a better future. But once in a while, a celebration does inspire the next generation of learners, educators, and
                                        innovators to strive for excellence and make their mark on the world stage.
                                    </p>
                                    <p>Know about <a href="https://forttuna.co/modern-healthcare-awards/">modern healthcare awards</a>!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <BlogSidebar /> */}
                </div>
            </div>
        </div>
    )

}

export default BlogSingle;
