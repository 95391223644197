import React from 'react';
import { Link } from 'react-router-dom';
import bg from '../../images/main-slider/image-2.webp';

const Features = () => {
    const handleClick = () => {
        window.scrollTo(10, 0);
    };

    const features = [
        {
            fIcon: 'flaticon-policy',
            title: 'Dedicated Seating for Awardees and Accompanying Guests',
            link: '',
        },
        {
            fIcon: 'flaticon-digital-learning',
            title: 'The FORTTUNA Global Excellence Awards will be streamed live on YouTube',
            link: '',
        },
        {
            fIcon: 'flaticon-trophy-3',
            title: 'Our Dignitaries will present the FORTTUNA Global Excellence Awards Trophy to all Winners',
            link: '',
        },
        {
            fIcon: 'flaticon-video-camera',
            title: 'Exclusive Photography and Videography coverage throughout the awards',
            link: '',
        },
        {
            fIcon: 'flaticon-handshake',
            title: 'Networking Opportunities with Global Industry Leaders',
            link: '',
        },
        {
            fIcon: 'flaticon-mic',
            title: 'Media / Press Coverage by Local and International Media Outlets of the Forttuna Awards',
            link: '',
        },
    ];

    return (
        <section className="feature-one" style={{ backgroundImage: `url(${bg})` }}>
            <div className="auto-container">
                <div className="sec-title title-anim" style={{ textAlign: 'center' }}>
                    <h2 className="sec-title_heading">Key Highlights of the Gala</h2>
                </div>
                <div className="row clearfix">
                    {features.map((feature, index) => (
                        <div className="feature-block_one col-lg-4 col-md-6 col-sm-12" key={index}>
                            <div className="feature-block_one-inner">
                                <div className="feature-block_one-icon">
                                    <i className={`fi ${feature.fIcon}`}></i>
                                </div>
                                <h4 className="feature-block_one-title fea" style={{ color: '#c88c19' }}>
                                    <Link onClick={handleClick} to={feature.link}>
                                        {feature.title}
                                    </Link>
                                </h4>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;
