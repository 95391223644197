import timg from '../images/resource/Randeer-Sood.jpg'
import timg1 from '../images/resource/Bu-abdullahh.jpg'

import tsimg from '../images/resource/Randeer-Sood.jpg'
import tsimg1 from '../images/resource/Bu-abdullahh.jpg'



const Teams = [
   {
      Id: '1',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Randhir Sud',
      slug: 'https://www.linkedin.com/in/dr-randhir-sud-884659a3/',
      title: "Chairman, Institute of Digestive and Hepatobiliary Sciences, Medanta the Medicity, NCR, India",
   },
   {
      Id: '2',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Bu Abdullah',
      slug: "https://www.linkedin.com/in/yaqoub-mossa-mohamed-9b7465334/",
      title: "Chairman - Bu Abdullah Investment Group, UAE",
   },

]

export default Teams;