import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/Aim-1.webp'
import abimg2 from '../../images/resource/Aim-2.webp'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one" id='about'>
            <div className="auto-container" style={{marginTop: '120px'}}>
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            {/* <div className="sec-title title-anim">
                                <div className="sec-title_title">Become Succesfuly day</div>
                                <h2 className="sec-title_heading">Our Vision</h2>
                            </div> */}
                            <div className="business-one_text"><p>The Forttuna Global Excellence Awards Program is designed to engage a distinguished and diverse demographic, spanning an array of industries and sectors. Our audience includes individuals, organizations, and enterprises that exemplify exceptional performance and groundbreaking innovation in their respective domains. This prestigious accolade celebrates remarkable achievements on a global scale, embracing an extensive spectrum of talents and contributions.</p><p>Our mission is to honor those who have redefined excellence within their fields of expertise. These honorees may include, but are not limited to, visionary business leaders, dedicated healthcare professionals, trailblazers in technology, advocates for sustainable innovation, and pioneers in arts and culture. By acknowledging such a wide array of accomplishments, the Forttuna Global Excellence Awards underscores the value of diverse perspectives and interdisciplinary success, fostering a global narrative of innovation and achievement.</p></div>
                            {/* <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/event-details/Registration-For-Opening-Workshop" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Book Ticket</span>
                                        <span className="text-two">Book Ticket</span>
                                    </span>
                                </Link>
                            </div> */}

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            {/* <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div> */}
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;