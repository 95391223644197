import timg1 from '../images/resource/moran-cerf.webp'
import timg2 from '../images/resource/Linda-Salvin.jpg'


import tsimg1 from '../images/resource/moran-cerf.webp'
import tsimg2 from '../images/resource/Linda-Salvin.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Moran Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      keynotelink: 'https://www.youtube.com/watch?v=Dud5-8bZQ9E',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '2',
      tImg: timg2,
      tsImg: tsimg2,
      name: 'Dr. Linda Salvin',
      slug: 'https://linkedin.com/in/linda-salvin-mph-phd-85009411/',
      keynotelink: 'https://www.youtube.com/watch?v=Dud5-8bZQ9E',
      title: 'MPH, PHD, Veteran Radio Talk Show Host, Healer and Metaphysician, Creator of Wicks of Wisdom, USA',
   },
]

export default Teams;