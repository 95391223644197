import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/partner/2024-51.png'
import pimg2 from '../../images/partner/2024-50.png'
import pimg3 from '../../images/partner/2024-52.png'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
]


const PartnerSection = (props) => {

    return (

        <section className="partner-one"  style={{marginTop: '40px'}}>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18', fontSize: '30px', marginBottom: '-20px' }}>Knowledge Partners</h2>
                </div>
                    {/* <h2 className="sec-title_heading">Partners</h2> */}
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="row">
                            {partners.map((partner, pitem) => (
                                <div className="partner-one_column col-lg-2 col-md-2 col-sm-2 " style={{padding: '0px'}} key={pitem}>
                                    <div  style={{ padding: '13px',}}>
                                        <img src={partner.pImg} alt="" style={{width:'170px'}} />
                                        {/* <p style={{color:'#fff', textAlign: 'center',}}>Test </p> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;