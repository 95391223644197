import React from 'react';
import { Link } from 'react-router-dom'
import blogtp from '../../images/resource/news-17.jpg'
import blogtp2 from '../../images/resource/author-5.jpg'
import blog1 from '../../images/resource/author-1.jpg'
import blog2 from '../../images/resource/author-2.jpg'
import blog3 from '../../images/resource/author-3.jpg'
// import blogs from '../../api/blogs';
import blogSingleImg1 from "../../images/resource/news-15.jpg"
import { useParams } from 'react-router-dom'
// import BlogSidebar from '../BlogSidebar';

const BlogSingle = (props) => {

    // const { slug } = useParams()

    // const BlogDetails = blogs.find(item => item.slug === slug)

    // const submitHandler = (e) => {
    //     e.preventDefault()
    // }

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    return (
        <div className="sidebar-page-container">
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="content-side col-lg-12 col-md-12 col-sm-12">
                        <div className="blog-detail">
                            <div className="blog-detail_inner">
                                <div className="blog-detail_image">
                                    <img src={blogSingleImg1} alt="World Sustainability Awards program" />
                                </div>
                                <div className="blog-detail_content">
                                    <div className="blog-detail_date">22 Nov 2024</div>
                                    <ul className="blog-detail_meta">
                                        <li style={{ listStyle: 'disc' }} ><span className="icon fa fa-regular fa-calendar"></span>by Forttuna Awards</li>
                                    </ul>
                                    <h1 style={{fontSize :'40px' , lineHeight: '43px'}}>World Sustainability Awards: Inspiring Progress</h1>
                                    <p>The World Sustainability Awards have emerged as the most coveted recognition for organizations and individuals who are <a href="https://forttuna.co/business-leaders-edition/">leaders</a> in environmental stewardship and sustainable development. The accolade not only honors excellence but also sets new benchmarks for industry practices worldwide.</p>
                                    <h2>What are the World Sustainability Awards?</h2>
                                    <p>The World Sustainability Awards are regarded as the highest recognition for companies and leaders who have made extraordinary contributions toward cultivating sustainable practices. Initially, these awards were driven by environmental consciousness. But today they are an eminent banner of excellence across industries.</p>
                                    <h3>Categories and Recognition Areas</h3>
                                    <p>The categories of the World Sustainability Awards commend individuals or organizations based on aspects of their contribution toward sustainability:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Environmental Innovation and Technology</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Sustainable Supply Chain Management</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><a href="https://greenbuildingcanada.ca/green-building-guide/what-is-green-building/">Green Building</a> and Infrastructure</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Renewable Energy Implementation</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Waste Management and Reduction</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Social Impact and Community Development</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Sustainable Agriculture and Food Production</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}><a href="https://www.forttuna.co/corporate-and-financial-award/">Corporate Leadership</a> in Sustainability</li>
                                    </ul>
                                    <h3>Influence on Business and Industry Standards</h3>
                                    <p>Impact from the World Sustainability Awards extends beyond the day of an award. Winners achieve the following benefits:</p>
                                    <h3>Growth in Business and Market Share</h3>
                                    <p>Companies that win these awards tend to enjoy higher brand valuation and brand awareness. Academic research has also found that sustainable companies have better long-term market performance.</p>
                                    <h3>Investor Confidence and Attraction</h3>
                                    <p>Award-winning companies tend to attract more investors, especially the eco-conscious ones. This award is a good way to ascertain fewer environmental risks and strong governance within the company.</p>
                                    <h3>Prominent Winners of These Accolades and Their Success Story</h3>
                                    <h4>Corporate Category Excellence</h4>
                                    <p>Recent corporate winners have demonstrated incredible innovations:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>A manufacturing company reduced carbon emissions by 75% through revolutionary process optimization</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>A technology firm achieved 100% renewable energy usage across its global operations</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>A retail chain implemented a zero-waste program across many locations</li>
                                    </ul>
                                    <h3>Individual Leadership Recognition</h3>
                                    <p>The World Sustainability Awards also celebrate individual leaders who have championed sustainability:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Scientists developing breakthrough environmental technologies</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CEOs implementing company-wide sustainability transformations</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Entrepreneurs deploying successful green business models</li>
                                    </ul>
                                    <h3>Application Process and Evaluation Criteria</h3>
                                    <h4>Submission Requirements</h4>
                                    <p>The World Sustainability Awards have high evaluation standards as follows:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Intensive documentation of sustainability measures</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Measurable environmental outcome metrics</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Strategy to deploy the solution and its scalability</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Financial feasibility and long-term sustainability prospects</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Stakeholder involvement and community benefit</li>
                                    </ul>
                                    <h3>Assessment Framework</h3>
                                    <p>Applications undergo an extensive process of screening as follows:</p>
                                    <ol>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Preliminary screening by experts in the respective industries</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Exhaustive technical assessment</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Site visits and corroborations</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Final assessment by the jury</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Public impact consideration</li>
                                    </ol>
                                    <h3>Industry-Specific Impact Stories</h3>
                                    <h4>Manufacturing Sector</h4>
                                    <p>Manufacturing winners have demonstrated how traditional industries can transform:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Implementation of circular economy principles</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Development of eco-friendly production processes</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovation in sustainable material usage</li>
                                    </ul>
                                    <h4>Technology and Digital Services</h4>
                                    <p>Tech companies have shown leadership through:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Energy-efficient data center operations</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Sustainable hardware design and recycling programs</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Digital solutions for environmental monitoring</li>
                                    </ul>
                                    <h4>Healthcare and Pharmaceuticals</h4>
                                    <p>Medical sector <a href="https://forttuna.co/modern-healthcare-awards/">winners</a> have excelled in:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Sustainable medical waste management</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Energy-efficient hospital operations</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Green packaging for pharmaceuticals</li>
                                    </ul>
                                    <h4>Future Trends and Developments</h4>
                                    <p>The World Sustainability Awards have also transformed over the years to address the new environmental issues emerging:</p>
                                    <h3>New Areas of Focus</h3>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Environmental management in AI</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Transparency in the supply chain using blockchain</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Biodiversity conservation efforts</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Climate resiliency interventions</li>
                                    </ul>
                                    <h4>Increasing International Acceptance</h4>
                                    <p>The awards are spreading their global scope and influence:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Increases in numbers from developing markets</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Inter-border sustainability partnership</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Regional awards and honors</li>
                                    </ul>
                                    <h5>Sectorial Cooperation</h5>
                                    <p>The prize promotes partners and know-how:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Sustainability summits are held annually</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Best practice sharing platforms exist</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Mentorship of industry is encouraged</li>
                                    </ul>
                                    <h5>Educational Significance</h5>
                                    <p>Winners are given a role in education on sustainability via:</p>
                                    <ul>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>University partnerships</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Professional development programs</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Public awareness campaigns</li>
                                    </ul>
                                    <p>Hence, creating change with World Sustainability Awards across various industries.</p>
                                    <h5>Forttuna Global Excellence Awards: Heroes of Sustainability, Authors of Legacies</h5>
                                    <p>Forttuna Global Excellence Awards is the celebration of remarkable achievements focusing on persons and organizations, that are making excellent contributions to their respective industries. It is a platform that honors excellence but also allows industry leaders to connect. This ranges from breakthrough innovations in healthcare categories like AYUSH, spas, wellness, to technological and financial breakthroughs. With a commitment to merit-based selection, the Forttuna Global Excellence Awards ensure that recognition is earned, not bought.</p>
                                    <p><strong>Why Join Us?</strong></p>
                                    <p>Being a <a href="https://www.forttuna.co/">Forttuna</a> Global Excellence Award winner means so much more than a title. It gives you the prestige of being part of an elite class, and the ability to network with visionary pioneers. What follows is increased credibility and impact! Since the awards strictly follow merit-based entry you can be confident of the value you add. You are placed on a distinct pedestal, raising your legacy for years ahead.</p>
                                    <p>The Forttuna Global Excellence Awards has a dedicated award celebrating sustainability and excellence.</p>
                                    <ol>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Sustainability Leader of the Year</li>
                                    </ol>
                                    <p>Other awards in the Business Leaders Category include:</p>
                                    <p><strong>Education</strong></p>
                                    <ol>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Business Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Business Woman of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CEO of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CFO of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Chief Learning Officer of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CIO of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Co-Founder of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Compliance Training Company of the Year (edtech)</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>COO of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>CTO of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Data Privacy Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Deputy Vice-Chancellor of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Director of Music of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Director of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Education Company of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Education Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Educator of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Emerging Author of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Emerging Entrepreneur in Higher Education of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Enrollment Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Entrepreneur of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Female Publisher of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Human-Centered Design Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Influencer of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovative Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovative Leadership Development Program of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Innovator Facilitator of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Learning &amp; Development Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Legal Advocacy Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Lifetime Achievement Award</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Lifetime Achievement Award In Leadership Development</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Lifetime Achievement in Higher Education Management</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Mentor of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Influential Young Educator of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Innovative Business Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Innovative Leader of the&nbsp;Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Inspiring Business Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Most Inspiring Leader of the&nbsp;Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Non-Profit of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Personal Leadership Executive Coach of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Quality Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Social Innovation Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Start-up of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Strategic &amp; Visionary Leader of the Year in Higher Education</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Strategic Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Strategist of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Visionary Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Visionary of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Well-being &amp; Human Flourishing Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Women Leader of the Year in Social Impact</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Young Business Leader of the Year</li>
                                    <li style={{ marginLeft: '20px', listStyleType: 'disc' }}>Young Entrepreneur of the Year</li>
                                    </ol>
                                    <p>Know more on <a href="https://forttuna.co/top-global-education-awards/">Top Global Education Awards.</a></p>
                                    <h6>Sustainable Future</h6>
                                    <p>The World Sustainability Awards represent more than the recognition of those involved; they act as a positive catalyst in the approach businesses take on environmental responsibility. It is something that guides organizations toward a greater future with increasing environmental challenges.</p>
                                    <p>The awards remind everyone that sustainable business is not only environmentally necessary but commercially viable too. They show that success in sustainability is attainable by all. Plus, they raise the bar high for corporates across all industries and regions.</p>
                                    <p><a href="https://participate.forttuna.co/">Participate here</a>!</p>
                                    <p>Read About <a href="https://forttuna.co/big-innovation-awards/">Big Innovation Awards.</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <BlogSidebar /> */}
                </div>
            </div>
        </div>
    )

}

export default BlogSingle;
