import React, { useEffect } from "react";
function App() {
  useEffect(() => {
    // Disable right-click
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });

    // Prevent copying
    document.addEventListener("copy", (event) => {
      event.preventDefault();
    });

    // Prevent cutting
    document.addEventListener("cut", (event) => {
      event.preventDefault();
    });

    // Clean up event listeners on unmount
    return () => {
      document.removeEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      document.removeEventListener("copy", (event) => {
        event.preventDefault();
      });
      document.removeEventListener("cut", (event) => {
        event.preventDefault();
      });
    };
  }, []);

  return (
    <div className="no-select">
      {/* <h1>This page disables right-click, copy, cut, and text selection.</h1>
      <p>Try selecting or right-clicking this text.</p> */}
    </div>
  );
}

export default App;