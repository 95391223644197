import timg from '../images/resource/manjulangani.webp'
import timg0 from '../images/resource/adil-sajwani.webp'
import timg1 from '../images/resource/moran-cerf.webp'
import timg2 from '../images/resource/Karan-Kaushik.jpg'
import timg3 from '../images/resource/sultan-alameri.webp'
import timg4 from '../images/resource/kaiser-raja.webp'
import timg5 from '../images/resource/bimal-chajjar.webp'
import timg6 from '../images/resource/Subramanyam-Yadavalli.webp'
import timg8 from '../images/resource/amit-sheth.webp'
import timg9 from '../images/resource/Ravi-Tharoor.jpg'
import timg10 from '../images/resource/Majid-Zangooei.jpg'
import timg11 from '../images/resource/Prof.-Sandra-Matz.jpg'
import timg12 from '../images/resource/shallen-verma.webp'
import timg13 from '../images/resource/Armin-Shahriari.jpg'

import tsimg from '../images/resource/manjulangani.webp'
import tsimg0 from '../images/resource/adil-sajwani.webp'
import tsimg1 from '../images/resource/moran-cerf.webp'
import tsimg2 from '../images/resource/Karan-Kaushik.jpg'
import tsimg3 from '../images/resource/sultan-alameri.webp'
import tsimg4 from '../images/resource/kaiser-raja.webp'
import tsimg5 from '../images/resource/bimal-chajjar.webp'
import tsimg6 from '../images/resource/Subramanyam-Yadavalli.webp'
import tsimg8 from '../images/resource/amit-sheth.webp'
import tsimg9 from '../images/resource/Ravi-Tharoor.jpg'
import tsimg10 from '../images/resource/Majid-Zangooei.jpg'
import tsimg11 from '../images/resource/Prof.-Sandra-Matz.jpg'
import tsimg12 from '../images/resource/shallen-verma.webp'
import tsimg13 from '../images/resource/Armin-Shahriari.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg0,
      tsImg: tsimg0,
      name: 'Dr. Adil Saeed Sajwani',
      slug: 'https://linkedin.com/in/dr-adil-sajwani',
      title: "Public Speaker, Healthcare Influencer, Deputy Medical Director, Mediclinic, UAE",
   },
   {
      Id: '2',
      tImg: timg1,
      tsImg: tsimg1,
      name: 'Dr. Mora Cerf',
      slug: 'https://linkedin.com/in/morancerf',
      title: 'Professor of Neuroscience & Business, Columbia University, USA',
   },
   {
      Id: '3',
      tImg: timg11,
      tsImg: tsimg11,
      name: 'Prof. Dr. Sandra Matz',
      slug: 'https://linkedin.com/in/sandra-matz-6824742b',
      title: 'Professor, Columbia Business School, USA',
   },
   {
      Id: '4',
      tImg: timg,
      tsImg: tsimg,
      name: 'Padmashri Dr. Manjula Anagani',
      slug: 'https://www.linkedin.com/in/dr-manjula-anagani-a5283718/',
      title: "Clinical Director, Women and Child Institute, Care Hospital, India",
   },
   {
      Id: '5',
      tImg: timg6,
      tsImg: tsimg6,
      name: 'Subramanyam Yadavalli',
      slug: 'https://linkedin.com/in/subramanyam-yadavalli-6ba30059',
      title: 'CEO, Apollo Hospitals, AP & International, India',
   },
   {
      Id: '6',
      tImg: timg12,
      tsImg: tsimg12,
      name: 'Dr. Shallen Verma',
      slug: "https://www.linkedin.com/in/shallen-verma-7b0b181/",
      title: "Specialist Periodontics, UAE",
   },
   {
      Id: '7',
      tImg: timg4,
      tsImg: tsimg4,
      name: 'Dr. Kaiser Raja',
      slug: "https://www.linkedin.com/in/kaiser-raja-47217416/",
      title: "Director of the Hepatology And Liver Transplantation Program at King’s College Hospital London, UAE",
   },
   {
      Id: '8',
      tImg: timg5,
      tsImg: tsimg5,
      name: 'Dr. Bimal Chhajer',
      slug: 'https://www.linkedin.com/in/bimal-chhajer-24a4aa29/',
      title: "Cardiologist, Founder, Saaol Heart Center, India",
   },

]

export default Teams;