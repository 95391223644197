import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/partner2024/1.png';
import pimg2 from '../../images/partner2024/2.png';
import pimg3 from '../../images/partner2024/3.png';
import pimg4 from '../../images/partner2024/4.png';
import pimg5 from '../../images/partner2024/5.png';
import pimg6 from '../../images/partner2024/6.png';
import pimg7 from '../../images/partner2024/7.png';
import pimg8 from '../../images/partner2024/8.png';
import pimg9 from '../../images/partner2024/9.png';
import pimg10 from '../../images/partner2024/10.png';
import pimg11 from '../../images/partner2024/11.png';
import pimg12 from '../../images/partner2024/12.png';
import pimg13 from '../../images/partner2024/13.png';
import pimg14 from '../../images/partner2024/14.png';
import pimg15 from '../../images/partner2024/15.png';
import pimg16 from '../../images/partner2024/16.png';
import pimg17 from '../../images/partner2024/17.png';
import pimg18 from '../../images/partner2024/18.png';
import pimg19 from '../../images/partner2024/19.png';
import pimg20 from '../../images/partner2024/20.png';
import pimg21 from '../../images/partner2024/21.png';
import pimg22 from '../../images/partner2024/22.png';
import pimg23 from '../../images/partner2024/23.png';
import pimg24 from '../../images/partner2024/24.png';
import pimg25 from '../../images/partner2024/25.png';
import pimg26 from '../../images/partner2024/American-Business-Time.png';
import pimg27 from '../../images/partner2024/AP.png';
import pimg28 from '../../images/partner2024/Benzianga.png';
import pimg29 from '../../images/partner2024/Business-Gazette.png';
import pimg30 from '../../images/partner2024/Business-Herald.png';
import pimg31 from '../../images/partner2024/Business-News-TOday.png';
import pimg32 from '../../images/partner2024/Business-Time-General.png';
import pimg33 from '../../images/partner2024/Business-UAE-Daily.png';
import pimg34 from '../../images/partner2024/CBS-News.png';
import pimg35 from '../../images/partner2024/Economic-Policy-Times.png';
import pimg36 from '../../images/partner2024/Fox-news-channel.png';
import pimg37 from '../../images/partner2024/Menafn.png';
import pimg38 from '../../images/partner2024/Middle-East-news-.png';
import pimg39 from '../../images/partner2024/Middle-east-news-General.png';
import pimg40 from '../../images/partner2024/NBC-News.png';
import pimg41 from '../../images/partner2024/News-Break-Middle-East.png';
import pimg42 from '../../images/partner2024/North-America.png';
import pimg43 from '../../images/partner2024/US-National-Times.png';
import pimg44 from '../../images/partner2024/US-Time-Gazette.png';
// import pimg45 from '../../images/partner2024/Zeetv.png';


const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg8,
    },
    {
        pImg: pimg9,
    },
    {
        pImg: pimg10,
    },
    {
        pImg: pimg11,
    },
    {
        pImg: pimg12,
    },
    {
        pImg: pimg13,
    },
    {
        pImg: pimg14,
    },
    {
        pImg: pimg15,
    },
    {
        pImg: pimg16,
    },
    {
        pImg: pimg17,
    },
    {
        pImg: pimg18,
    },
    {
        pImg: pimg19,
    },
    {
        pImg: pimg20,
    },
    {
        pImg: pimg21,
    },
    {
        pImg: pimg22,
    },
    {
        pImg: pimg23,
    },
    {
        pImg: pimg24,
    },
    {
        pImg: pimg25,
    },
    {
        pImg: pimg26,
    },
    {
        pImg: pimg27,
    },
    {
        pImg: pimg28,
    },
    {
        pImg: pimg29,
    },
    {
        pImg: pimg30,
    },
    {
        pImg: pimg31,
    },
    {
        pImg: pimg32,
    },
    {
        pImg: pimg33,
    },
    {
        pImg: pimg34,
    },
    {
        pImg: pimg35,
    },
    {
        pImg: pimg36,
    },
    {
        pImg: pimg37,
    },
    {
        pImg: pimg38,
    },
    {
        pImg: pimg39,
    },
    {
        pImg: pimg40,
    },
    {
        pImg: pimg41,
    },
    {
        pImg: pimg42,
    },
    {
        pImg: pimg43,
    },
    {
        pImg: pimg44,
    },
    // {
    //     pImg: pimg45,
    // },
]


const PartnerSection = (props) => {

    return (

        <section className="partner-one" style={{marginBottom: '40px',marginTop: '25px'}}>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18', fontSize: '30px', marginBottom: '-20px' }}>Digital Media Coverage</h2>
                </div>
                    {/* <h2 className="sec-title_heading">Partners</h2> */}
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="row">
                            {partners.map((partner, pitem) => (
                                <div className="partner-one_column col-lg-2 col-md-2 col-sm-2" style={{padding:'0px'}} key={pitem}>
                                    <div  style={{ padding: '13px', }}>
                                        <img src={partner.pImg} alt=""  style={{ width: '170px'}}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;



