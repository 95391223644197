import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/resource/business-1.jpg'
import abimg2 from '../../images/resource/business-2.jpg'
import bg1 from '../../images/background/pattern-21.png'


const Business = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="business-one business-on">
            <div className="auto-container" >
                <div className="row clearfix">
                    <div className="business-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_content-inner">
                            <div className="sec-title title-anim">
                                <h2 className="sec-title_heading">Introduction</h2>
                            </div>
                            {/* <div className="business-one_bold-text">Grow, scrape their knees, try new things, to be vulnerable, and to have epic adventures together</div> */}
                            <div className="business-one_text"><p className='color2' style={{textAlign: 'justify'}}>The Forttuna Global Excellence Awards Program recognizes that health, whether physical or mental, is the most crucial facet of our lives. Healthcare stands as one of the most righteous and  <a href="https://forttuna.co/award-categories/">noble professions</a> known to mankind. Through our excellence awards, we seize the opportunity to honor and celebrate those who work tirelessly in the shadows to enrich and improve the quality of our lives. 
                            These awards are designed to honor the first line of defense against illnesses – our very own angels with stethoscopes. <br /><br/> The India Edition of the Forttuna Global Excellence Awards Program is dedicated to <a href="https://participate.forttuna.co/"> celebrate and cherishing</a> these extraordinary individuals, organizations, and institutions within the medical industry. We aim to recognize their remarkable contributions, achievements, and innovations that enhance the services and overall healthcare practices in India.	</p>
                            </div>
                            <div className="business-one_button">
                                <Link onClick={ClickHandler} to="/schedule-health-wellness-india/" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Agenda</span>
                                        <span className="text-two">Agenda</span>
                                    </span>
                                </Link>
                            </div>

                        </div>
                    </div>
                    <div className="business-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="business-one_image-inner">
                            <div className="business-one_pattern-one" style={{ backgroundImage: `url(${bg1})` }}></div>
                            {/* <div className="business-one_experiance">
                                <div className="business-one_counter"><span className="odometer" data-count="35"></span>35years</div>
                                <i>Successful Worldwide</i>
                                <strong>Conference</strong>
                            </div> */}
                            <div className="business-one_image">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="business-one_image-two">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Business;