import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/partner/1.webp'
import pimg2 from '../../images/partner/2.webp'
import pimg3 from '../../images/partner/3.webp'
import pimg4 from '../../images/partner/4.webp'
import pimg5 from '../../images/partner/5.webp'
import pimg6 from '../../images/partner/6.webp'
import pimg7 from '../../images/partner/7.webp'
import pimg9 from '../../images/partner/9.webp'
import pimg10 from '../../images/partner/10.webp'
import pimg11 from '../../images/partner/11.webp'
import pimg12 from '../../images/partner/12.webp'
import pimg13 from '../../images/partner/13.webp'
import pimg14 from '../../images/partner/14.webp'
import pimg15 from '../../images/partner/15.webp'
import pimg16 from '../../images/partner/16.webp'
import pimg17 from '../../images/partner/17.webp'
import pimg18 from '../../images/partner/18.webp'
import pimg19 from '../../images/partner/19.webp'
import pimg20 from '../../images/partner/20.webp'
import pimg21 from '../../images/partner/21.webp'
import pimg22 from '../../images/partner/22.webp'
import pimg23 from '../../images/partner/23.webp'
import pimg24 from '../../images/partner/24.webp'
import pimg25 from '../../images/partner/25.webp'
import pimg26 from '../../images/partner/26.webp'
import pimg27 from '../../images/partner/27.webp'
import pimg28 from '../../images/partner/28.webp'

const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg4,
    },
    {
        pImg: pimg5,
    },
    {
        pImg: pimg6,
    },
    {
        pImg: pimg7,
    },
    {
        pImg: pimg9,
    },
    {
        pImg: pimg10,
    },
    {
        pImg: pimg11,
    },
    {
        pImg: pimg12,
    },
    {
        pImg: pimg13,
    },
    {
        pImg: pimg14,
    },
    {
        pImg: pimg15,
    },
    {
        pImg: pimg16,
    },
    {
        pImg: pimg17,
    },
    {
        pImg: pimg18,
    },
    {
        pImg: pimg19,
    },
    {
        pImg: pimg20,
    },
    {
        pImg: pimg21,
    },
    {
        pImg: pimg22,
    },
    {
        pImg: pimg23,
    },
    {
        pImg: pimg24,
    },
    {
        pImg: pimg25,
    },
    {
        pImg: pimg26,
    },
    {
        pImg: pimg27,
    },
    {
        pImg: pimg28,
    },
]


const PartnerSection = (props) => {

    return (

        <section className="partner-one"  style={{marginTop: '40px'}}>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <h2 className="sec-title_heading" style={{ textAlign: 'left', color: '#c99c18' ,fontSize: '30px', marginBottom: '-20px', marginLeft: '15px' }}>Partners</h2>
                </div>
                    {/* <h2 className="sec-title_heading">Partners</h2> */}
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="row">
                            {partners.map((partner, pitem) => (
                                <div className="partner-one_column col-lg-2 col-md-2 col-sm-2" key={pitem}>
                                    <div  style={{ padding: '13px'}} >
                                        <Link to="/"><img src={partner.pImg} alt="" style={{width:'170px'}} /></Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;