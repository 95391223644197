import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards: Business Awards in India';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="Showcase your company's excellence on the world stage. Enter the Business Excellence Awards and connect with a global network of industry pioneers." />
            <meta property="og:title" content="The Forttuna Global Excellence Awards: Business Awards in India" />
            <meta property="og:description" content="Showcase your company's excellence on the world stage. Enter the Business Excellence Awards and connect with a global network of industry pioneers." />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="Business Excellence Awards" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards: Business Awards in India' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna Business Excellence Awards" />
                        </div>
                        <h2 style={{color: '#fff'}}><strong>The Forttuna Global Excellence Awards: Business Awards in India</strong></h2>
                        <h1 style={{fontSize :'40px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led Business Awards In India: Be Recognized on the World Stage</strong></h1>
                        <p>Business leaders bring about economic value and drive growth for their organization. Business awards in India, thus, celebrate the vision, innovation, and excellence of such business leaders across industries.</p>
                        <p>One such prestigious platform that honors remarkable business leaders and organizations in India is the Fortuna Global Excellence Awards. The esteemed award ceremony confers individuals and companies that have exceptional achievements, innovation, and leadership across various industries.</p>
                        <h3 style={{color: '#fff'}}>What are the Forttuna Global Excellence Business Awards in India?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Prestigious Recognition:</strong> Honors outstanding achievements across various industries.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Impact:</strong> Recognizes individuals and companies making a significant global mark.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Networking Opportunities:</strong> Brings like-minded individuals together and fosters collaboration.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Inspiring Future Leaders:</strong> Motivates and inspires the <a href="https://www.forbes.com/councils/forbesbusinesscouncil/2024/08/07/20-challenges-for-the-next-generation-of-business-leaders/">next generation of business leaders</a>.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Driving Innovation:</strong> Promotes innovation and creativity in the business world.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Why Participate in the Best Business Awards in India?</h3>
                        <ul>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Achieve national <a href="https://www.forttuna.co/health-wellness-edition-india/">recognition</a> for your company's innovation and leadership in the industry.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Highlight your outstanding individual talent and inspire the business community through remarkable achievements.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Interact with industry leaders at India's most prestigious business awards India ceremony.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Draw more credibility to your brand's profile and attract exciting new opportunities.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Gain the spotlight through extensive media coverage and highlight your accomplishments and successes.</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Experience a fair and transparent evaluation by a distinguished panel of experts from diverse industries.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Business Awards India Categories :</h3>
                        <p><strong>A. Architecture, Construction &amp; Engineering</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Eco-System Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Construction Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Interior Designer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Legal Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Company of the Year</li>
                        </ol>
                        <p><strong>B. Automobile</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sales Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategist of the Year</li>
                        </ol>
                        <p><strong>C. Aviation</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Aviation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Procurement Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        </ol>
                        <p><strong>D. Education</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Learning Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Co-Founder of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Compliance Training Company of the Year (edtech)</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Data Privacy Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Deputy Vice-Chancellor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Director of Music of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Education Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Education Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Educator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Author of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur in Higher Education of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Enrollment Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Female Publisher of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Human-Centered Design Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leadership Development Program of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator Facilitator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Learning &amp; Development Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Legal Advocacy Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award In Leadership Development</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement in Higher Education Management</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Influential Young Educator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Leader of the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Inspiring Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Inspiring Leader of the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Non-Profit of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Personal Leadership Executive Coach of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Quality Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Social Innovation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic &amp; Visionary Leader of the Year in Higher Education</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Well-being &amp; Human Flourishing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Women Leader of the Year in Social Impact</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>E. Entertainment and Media</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Animation and Visual Effects Community Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Journalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Content Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Film and Television Production Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Presenter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Travel Influencer of the Year</li>
                        </ol>
                        <p><strong>F. Fashion and Beauty</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Board Member of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cultural Heritage Fashion Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CX Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Innovation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fashion Brand of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Fragrance Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Haircare Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Business Development Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>G. Finance and Insurance</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Banking Professional of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cash Management Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Compliance Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Finance Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Finance Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>FinTech Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Impact Investor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Investment Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Regional Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Venture Capitalist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Wealth Management Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>H. Governance</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Leader of the Year - Government Services</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        </ol>
                        <p><strong>I. Hospitality</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Facility Management Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Travel Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Travel Professional of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        </ol>
                        <p><strong>I. Human Resources</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leadership Educator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CHRO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>HR Leader &amp; Woman Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>HR Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>HR Outsourcing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>HR Outsourcing Woman Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influential Talent Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Talent Acquisition Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>J. Law</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Corporate Lawyer of the Year</li>
                        </ol>
                        <p><strong>K. Manufacturing</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        </ol>
                        <p><strong>L. Market Research</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Market Research Expert of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Research Services Organisation of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Researcher of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Supply Chain Professional of the Year</li>
                        </ol>
                        <p><strong>M. Marketing</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Advertising Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Brand Strategy Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Coach of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Commercial Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Content Marketer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Data Analytics Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Marketing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Female Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Humanitarian Philanthropy Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Business Idea of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Integrated Marketing Communications Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Strategist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Media &amp; Advertising Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sales Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Social Media Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sustainability Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>VP of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>N. Real Estate</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Strategist of&nbsp;the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Marketer of the Year</li>
                        </ol>
                        <p><strong>O. SME&rsquo;s</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Competency Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Consultancy of the Year (Outsourcing Services)</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Evangelist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Operations</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Managing Director of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Pastry Chef of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Patisserie Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>SME of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Women Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        </ol>
                        <p><strong>P. Technology</strong></p>
                        <ol>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Ad-Tech Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>AVP of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Blockchain Consultant of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Transformation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CFO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Interoperability Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Revenue Officer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cloud Computing Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CMO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Creative Developer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Business of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Cybersecurity Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Data Center Management Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Disruptor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Technology Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Transformation Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Technology Advocate of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Influencer of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Icon of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Innovator of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>IT Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Agriculture and Development</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Achievement Award in Engineering and Manufacturing</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Tech Innovator Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Power and Water Utilities Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Sales and Marketing Leader of the&nbsp;Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Scientist of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Cloud Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Strategic Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Utility Industry Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Website Design &amp; Development Company of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Leader of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Business Woman of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young CEO of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                        <li style={{ listStyle: 'disc', color: '#fff' }}>Young Technology Leader of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to shine a light at the best business awards in India?</h4>
                        <h5 style={{color: '#fff'}}>Participate today!</h5>
                        <p>Let's celebrate your achievements together at this Business Awards India!</p>
                        <p>Be a part of the best business awards in India.</p>
                        <p>Know About <a href="https://forttuna.co/international-business-awards/">International Business Award</a>.</p>
                        <p>Learn More About <a href="https://forttuna.co/best-sustainability-awards/">Best Sustainability Awards</a>.</p>
                        <p><a href="https://participate.forttuna.co/">Participate here</a>!</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
