import React, { Fragment , useEffect } from 'react';
import { Helmet } from 'react-helmet';
import hero1 from '../../images/main-slider/Dubai-Banner-2-Final.webp'
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Events from '../../api/Events';
import { Link } from 'react-router-dom';
import eimg1 from '../../images/resource/leadership-1.jpg'
import sSimg from '../../images/award/Banking-Tech-Awards.webp';
import Subscribe2 from '../../components/Subscribe2/Subscribe2';
import Footer2 from '../../components/footer2/Footer2';
// eTitle = 'The Forttuna Global Excellence Awards: Fintech Awards'

const EventSinglePage = (props) => {
    // const { slug } = useParams()

    // const eventDetails = Events.find(item => item.slug === slug)

    useEffect(() => {
        document.title = 'The Forttuna Global Excellence Awards for Doctors in India';
    }, []);
    return (
        <>
        <Helmet>
            <meta name="description" content="The Forttuna Awards for Doctors in India is for your contributions to healthcare. Join fellow innovators and elevate your impact in the industry!" />
            <meta property="og:title" content="The Forttuna Global Excellence Awards for Doctors in India" />
            <meta property="og:description" content="The Forttuna Awards for Doctors in India is for your contributions to healthcare. Join fellow innovators and elevate your impact in the industry!" />
            <meta property="og:image" content={hero1} />
            <meta property="og:url" content={hero1  } />
            <meta name="keywords" content="awards for doctors in India" />
            <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle='The Forttuna Global Excellence Awards for Doctors in India' pagesub={''} pageTitle2={''}/>
            <section className="event-detail">
                <div className="auto-container">
                    <div className="inner-container">
                        <div className="event-detail_image">
                            <img src={sSimg} alt="Forttuna awards for doctors in India" />
                        </div>
                        <h2 style={{color: '#fff'}}>The Forttuna Global Excellence Awards For Doctors In India</h2>
                        <h1 style={{fontSize :'35px' , lineHeight: '43px', color: '#fff'}}><strong>First-ever Jury Led</strong> Awards for Doctors in India: Be Recognized on the World Stage</h1>
                        <p>
                            The Awards for Doctors in India is dedicated to the pioneers of India&rsquo;s health landscape and their tireless efforts in saving lives. The Forttuna Global Excellence Awards celebrates outstanding medical professionals and
                            institutions that are transforming healthcare and advancing patient care.
                        </p>
                        <p>
                            By highlighting medical excellence, innovative practices, and ethical standards of a doctor, such awards show their dedication. They acknowledge the significant impact one has on the healthcare sector. What&rsquo;s more? They entail a
                            huge networking opportunity. Leading doctors can become a part of the network of leading figures who are driving meaningful change in the <a href="https://www.forttuna.co/health-wellness-edition/">healthcare</a> industry.
                        </p>
                        <h3 style={{color: '#fff'}}>What are Forttuna Global Excellence Awards for Doctors in India?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Global Honor:</strong> Receive acknowledgment from an esteemed panel in this prestigious, merit-based awards program.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Peer Recognition:</strong> Be honored alongside a network of doctors with similar values.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}><strong>Networking Gig</strong>: Connect and collaborate with pioneers in the industry.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}><strong>Why Participate in the</strong> Awards for Doctors in India?</h3>
                        <ul>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Showcase your <a href="https://www.medicalnewstoday.com/categories/medical-innovation">medical innovation</a> on a renowned platform and gain recognition for your inspiring work in Indian healthcare.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Attract new opportunities and partnerships through enhanced brand visibility in the medical community.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Highlight your achievements and inspire the next generation of Doctors in India.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Engage in meaningful discussions with top doctors and healthcare experts at the exclusive awards ceremony.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Gain attention from national and international media outlets, bringing your accomplishments into the spotlight.</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>A panel of global experts will evaluate your contributions.</li>
                        </ul>
                        <h3 style={{color: '#fff'}}>Awards Categories for Doctors in India</h3>
                        <p>Below are the award categories celebrating remarkable achievements in health and wellness space. Categories for medical professionals in their diverse specialty is also listed.</p>
                        <p><strong>A. AYUSH / Spa / Wellness / Yoga Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Wellness Brand of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Energy Medicine Practitioner of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year Health and Wellness</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Holistic Healer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Holistic Nutrition and Lifestyle Program of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Innovative Product of the Year in AYUSH</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Centre/Retreat of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Yoga Coach of the Year</li>
                        </ol>
                        <p><strong>B. Company and Brands</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>AI Dermatology Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Anti-Aging Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Care Home Pharmacy Services Provider of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dental Clinic of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Tech Company of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Femtech&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Consultancy of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Idea of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Medical Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Long-Term Care Provider of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Marketing Company&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Equipment Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Tourism Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Natural Ingredient Skincare Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Nursing Recruitment Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Distributor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharma Innovation Organisation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pharmacy Retail Chain of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Product of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Product of the Year - Contraceptive</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Protein Snack of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Public Eye Health Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Public Healthcare Initiative of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Recruiter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Stress Management Organisation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Company of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Well-being Start-up of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Product of the Year</li>
                        </ol>
                        <p><strong>C. CXO's - Health &amp; Wellness</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CEO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Audit Executive (CAE) of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chief Product Officer of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CISO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CMIO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>COO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>CTO of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young CEO of the Year</li>
                        </ol>
                        <p><strong>D. Hospital &amp; Clinic Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic/Cosmetic Surgery Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Clinic Chain of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetic Dermatology Clinic of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Rehabilitation Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Eye Hospital of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fertility Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Hair Clinic of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Hospital of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Pain Management Program of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Longevity Centre of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Tech Organization of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health care Center of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Program of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Quality and Patient Safety Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Single Specialty Hospital of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Women Surgical Clinic of the Year</li>
                        </ol>
                        <p><strong>E. Individual Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetic Consultant of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Aesthetics Skincare Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>AI Leader of the Year in Health and Social&nbsp;Care</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Animal Advocate of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Ayurvedic Physician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Behaviour Analyst of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Bio-Technology Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cardiothoracic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Care Services Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Child Advocacy &amp; Therapy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chiropractor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Chronic Condition and Wellbeing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Clinical Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Community Health Impact Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Constructive Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Consultant Psychiatrist and Senior Physician Executive of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetic Plastic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Cosmetologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Counsellor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Data Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dentist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dermatologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Diabetologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Dietician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Digital Restorative Dentist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Diversity Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Educator of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emergency Physician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Leader of the Year in Home Care</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Medical Communications Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Executive Healthcare Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Executive Women of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Eye Care Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Facial Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>FemTech Platform of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fitness Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fitness Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fitness Solution Expert of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Founder of the Year in Wellness</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Fundraising Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>General Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Global Impact Leader of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Growth and Expansion Leader of the Year in Home Care</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Gynecologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Health &amp; Wellness Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Leader of the Year in Chiropractic</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Health Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Compliance Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Education Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Finance Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Marketing Strategy Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Mentor Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Regulatory Affairs Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Researcher of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Staffing Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Technology Integration Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Technology Leader&nbsp;of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Healthcare Woman of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>HR of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Infertility Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Health and Well-being Programme of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Leader of the Year in Gynecology and Oncology</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Mental Health Idea of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Product Distributor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Innovative Solutions Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Integrative Health Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lab Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Lifetime Achievement Award in Natural Medicine</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Managing Director of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Maxillofacial Prosthodontist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Medical Device Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>MedTech Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Professional of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Health Promoter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mental Healthcare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Mentor of the Year in Quality</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Metaphysician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Midwife of the Year (Wellness and Holistic Care)</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Minimally Invasive Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Molecular Biologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Most Inspiring Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Neurologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Neuroscience Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Nursing Coach of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Nutritionist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Oncologist Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Ophthalmologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Optometrist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Oral Health Promoter of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopaedic Sports Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Orthopedic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Osteopath of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pain Management Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Pathologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Patient-Centered Innovation Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Physician of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Physiotherapist of&nbsp;the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Plastic Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Podiatrist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Posthumous - Lifetime Achievement Award</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Postural Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>President of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Psychologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Psychotherapist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Public Health Specialist of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Restorative Dentist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Scientist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Skin Cancer Specialist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Skincare Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Skincare Training and Education Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Change Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Social Impact Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Speech Language Pathologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Spiritual Education Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Strategist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Stress Management Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Supply Chain Professional of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Surgeon of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Sustainability Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Technology Leader of the Year in Healthcare and Wellness</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Urologist of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Mentor of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Wellness Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Women of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Co-Founder of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Entrepreneur of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Leader of the&nbsp;Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Healthcare Visionary of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Wellness Leader of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Young Wellness Visionary of the Year</li>
                        </ol>
                        <p><strong>F. Medical College / Institute Category</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Clinical Research Innovation of the Year</li>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Education and Placement Leader of the Year</li>
                        </ol>
                        <p><strong>G. Wellness and Skincare</strong></p>
                        <ol>
                            <li style={{ listStyle: 'disc', color: '#fff' }}>Emerging Skincare Product of the Year</li>
                        </ol>
                        <h4 style={{color: '#fff'}}>Ready to be recognized? Participate today!</h4>
                        <p>Celebrate your remarkable contributions at this esteemed awards ceremony for doctors in India.</p>
                        <p><a href="https://participate.forttuna.co/">Join us</a>!</p>
                        <p>Learn More&nbsp;About <a href="https://forttuna.co/awards/health-services-awards/">Health Services Award.</a></p>
                        <p>Know More on <a href="https://forttuna.co/top-recognition-awards/">Top Recognition Awards</a>.</p>
                    </div>
                </div>
            </section>
            <Subscribe2 />
            <Footer2/>
            <Scrollbar />
        </Fragment>
        </>
    )
};
export default EventSinglePage;
